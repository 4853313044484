import React, { FC } from 'react';
import classnames from 'classnames';

import { ISectionComponentProps } from '..';
import { IPromoBlockSection } from '../../../@generated/@types/contentful';
import styles from './promoBlock.module.css';
import sectionIndentsStyles from '../sectionIndents.module.css';
import { PromoBlockCard } from './promoBlockCard';

interface IPromoBlockProps extends ISectionComponentProps {
    entry: IPromoBlockSection;
}

const PromoBlock: FC<IPromoBlockProps> = ({ entry }) => {
    const { fields } = entry;
    const backgroundColor = fields.backgroundColor?.fields.hexColor;
    const titleColor = fields.titleColor?.fields.hexColor;
    const descriptionColor = fields.descriptionColor?.fields.hexColor;

    return (
        <div className={classnames(styles.wrapper, sectionIndentsStyles.wrapper)}>
            {fields.cards.map((card) => (
                <PromoBlockCard
                    key={card.sys.id}
                    {...card}
                    backgroundColor={backgroundColor}
                    titleColor={titleColor}
                    descriptionColor={descriptionColor}
                />
            ))}
        </div>
    );
};

export default PromoBlock;
