import React, { FC } from 'react';
import classnames from 'classnames';
import { IOrderModel, OrderStatusEnumModel } from '../../../../@generated/webExpApi';
import { useDomainMenu, useGlobalProps } from '../../../../redux/hooks';
import ContentfulImage from '../../../atoms/ContentfulImage';
import { InspireLink } from '../../../atoms/link';
import formatOrderDate from '../../../../common/helpers/order/formatOrderDate';
import { IOrderItemProduct } from '../../../../common/hooks/useOrderItem';
import Loader from '../../../atoms/Loader';
import {
    ORDER_LABEL_TRUNCATE_CLASSNAME,
    SHOW_ITEM_FULFILLMENT_TYPE,
    SHOW_RECEIVED_BORDER,
    SHOW_UNAVAILABLE_LABEL_BOTTOM,
} from './constants';

import styles from './recentOrderItem.module.css';

interface IRecentOrderItemProps {
    order: IOrderModel;
    isOrderActive: boolean;
    products: IOrderItemProduct[];
    unavailableItemsText?: string;
    showCancelledOrderStatus?: boolean;
    renderActions: () => JSX.Element;
}

const fulfillmentTypes = { pickup: 'Pickup', delivery: 'Delivery' };

const RecentOrderItem: FC<IRecentOrderItemProps> = ({
    order,
    products,
    unavailableItemsText,
    showCancelledOrderStatus,
    isOrderActive,
    renderActions,
}) => {
    const productsCount = order.products.reduce((acc, item) => acc + item.quantity, 0);
    const isPlural = productsCount > 1;
    const countLabel = `${productsCount} ${isPlural ? 'items' : 'item'}`;
    const orderLabel = products
        .map((product) => {
            const sizeLabel = product.sizeLabel ? `(${product.sizeLabel})` : '';
            return `${product.quantity}x ${product.domainProduct?.name || product.description} ${sizeLabel}`;
        })
        .join(', ');

    const { loading: isMenuLoading } = useDomainMenu();

    const { productsById, modifierItemsById } = useGlobalProps();
    const firstContentfulProductFromOrder = productsById[order?.products[0]?.productId];
    const defaultContentfulProduct = modifierItemsById['default'];

    const displayDateTime = formatOrderDate(order.pickupTime, order.fulfillment?.location?.timezone);

    const isCancelledOrder = order.orderStatus === OrderStatusEnumModel.Cancelled;

    const renderUnavailableSection = () =>
        isMenuLoading ? (
            <span className={styles.loader}>
                <Loader size={10} />
            </span>
        ) : (
            unavailableItemsText && (
                <div className={classnames('t-paragraph-hint', styles.unavailableItems)}>{unavailableItemsText}</div>
            )
        );

    return (
        <div
            className={classnames(styles.container, {
                [styles.receivedBorder]: SHOW_RECEIVED_BORDER && isOrderActive,
            })}
        >
            <div className={styles.infoBlock}>
                <div
                    className={classnames('t-tag', styles.date, {
                        [styles.receivedBackground]: isOrderActive,
                        [styles.cancelledBackground]: isCancelledOrder,
                    })}
                >
                    <time>{displayDateTime}</time>
                    {showCancelledOrderStatus && isCancelledOrder && <span> - Canceled</span>}
                </div>
                <span className={classnames('t-paragraph-hint', styles.meta)}>
                    {SHOW_ITEM_FULFILLMENT_TYPE && <>{fulfillmentTypes[order.fulfillment.type?.toLowerCase()]} | </>}
                    {countLabel}
                </span>
            </div>
            <InspireLink link={`/account/orders/order-details/?id=${order.id}`}>
                <div className={styles.orderContent}>
                    <ContentfulImage
                        className={styles.orderImage}
                        asset={(firstContentfulProductFromOrder || defaultContentfulProduct)?.fields?.image}
                        maxWidth={52}
                    />
                    <div className={styles.orderProducts}>
                        <div
                            className={classnames(
                                't-paragraph-small',
                                ORDER_LABEL_TRUNCATE_CLASSNAME,
                                styles.orderBlock
                            )}
                        >
                            {orderLabel}
                        </div>
                        {!SHOW_UNAVAILABLE_LABEL_BOTTOM && renderUnavailableSection()}
                    </div>
                </div>
            </InspireLink>
            <div className={styles.ctaBlock}>
                {renderActions()}
                {SHOW_UNAVAILABLE_LABEL_BOTTOM && renderUnavailableSection()}
            </div>
        </div>
    );
};

export default RecentOrderItem;
