import React, { FC } from 'react';

import { ISectionComponentProps } from '..';
import { IImageBlockSection } from '../../../@generated/@types/contentful';
import styles from './imageBlock.module.css';
import { InspireButton } from '../../atoms/button';
import classnames from 'classnames';
import ContentfulImage from '../../atoms/ContentfulImage';
import sectionIndentsStyles from '../sectionIndents.module.css';
import SectionHeader from '../../atoms/sectionHeader';
import { IMAGE_MAX_WIDTH, BOX_BULLET_SHOW, SUBTITLE_TYPOGRAPHY, IS_CTA_AT_RIGHT, SECTION_TITLE } from './constants';
import LegalMessage from '../../atoms/legalMessage';
import { spaces2underscores } from '../../../lib/gtm';
import TextWithTrademark from '../../atoms/textWithTrademark';
import ViewDetails from '../../molecules/viewDetails';
import { GTM_EXPLORE_MORE_EVENT } from '../../../common/services/gtmService/constants';
import { useAppDispatch } from '../../../redux/store';

interface IImageBlockProps extends ISectionComponentProps {
    entry: IImageBlockSection;
}

const ImageBlock: FC<IImageBlockProps> = ({ entry: { fields } }) => {
    const dispatch = useAppDispatch();

    if (!fields) return null;
    const backgroundColor = fields.backgroundColor?.fields.hexColor;
    const titleColor = fields.titleColor?.fields.hexColor;
    const descriptionColor = fields.descriptionColor?.fields.hexColor;

    const isSingleCard = fields.cards.length === 1;
    const isDoubleCard = fields.cards.length === 2;
    const isTripleCard = fields.cards.length === 3;

    const handleClick = (linkTitle: string) => {
        if (linkTitle && fields?.name === SECTION_TITLE) {
            dispatch({ type: GTM_EXPLORE_MORE_EVENT, payload: linkTitle });
        }
    };

    return (
        <div className={classnames(sectionIndentsStyles.wrapper, styles.container)}>
            {fields?.name && (
                <SectionHeader
                    className={classnames(sectionIndentsStyles.wrapper, styles.header)}
                    textClassName="t-header-h2"
                    text={fields.name}
                    showBullet={BOX_BULLET_SHOW}
                />
            )}
            <div
                className={classnames(styles.wrapper, {
                    [styles.grid1]: isSingleCard,
                    [styles.grid2]: isDoubleCard,
                    [styles.grid3]: isTripleCard,
                })}
            >
                {fields?.cards?.map((card) => {
                    const {
                        fields: fieldsCards,
                        sys: { id },
                    } = card;
                    if (!fieldsCards) return null;
                    const {
                        image,
                        cta,
                        description,
                        title,
                        subtitle,
                        imageBlockCtaType,
                        legalMessage,
                        viewDetails,
                    } = fieldsCards;

                    const dataGtmId = `promoBanner-${spaces2underscores(title)}`;

                    const CardContent = (
                        <>
                            <ContentfulImage
                                asset={image}
                                className={styles.image}
                                maxWidth={IMAGE_MAX_WIDTH}
                                imageAlt=""
                            />
                            <div className={classnames(styles.content, { [styles.ctaAtRight]: IS_CTA_AT_RIGHT })}>
                                <div className={styles.text}>
                                    {subtitle && (
                                        <p
                                            className={classnames(SUBTITLE_TYPOGRAPHY, 'truncate', styles.subtitle)}
                                            title={subtitle}
                                        >
                                            {subtitle}
                                        </p>
                                    )}
                                    <TextWithTrademark
                                        tag={fields.name ? 'h4' : 'h3'}
                                        className={classnames('t-header-card-title', 'truncate', styles.title, {
                                            titleColor: !!titleColor,
                                        })}
                                        title={title}
                                        text={title}
                                    />
                                    <div>
                                        {description && (
                                            <p
                                                className={classnames(
                                                    't-paragraph',
                                                    'truncate-at-2',
                                                    styles.description,
                                                    {
                                                        descriptionColor: !!descriptionColor,
                                                    }
                                                )}
                                                title={description}
                                            >
                                                {description}
                                                {viewDetails && (
                                                    <>
                                                        {' '}
                                                        <ViewDetails
                                                            viewDetails={viewDetails}
                                                            className={styles.viewDetails}
                                                        />
                                                    </>
                                                )}
                                            </p>
                                        )}
                                        {legalMessage && (
                                            <LegalMessage
                                                className={classnames(styles.legalMessage, styles.description, {
                                                    descriptionColor: !!descriptionColor,
                                                })}
                                                legalMessage={legalMessage}
                                            />
                                        )}
                                    </div>
                                </div>

                                {cta && (
                                    <InspireButton
                                        type={imageBlockCtaType || 'primary'}
                                        text={cta.fields.name}
                                        className={classnames('truncate', styles.cta)}
                                        gtmId={dataGtmId}
                                        link={cta.fields.link}
                                        onClick={() => handleClick(cta.fields.name)}
                                    />
                                )}
                            </div>
                        </>
                    );

                    if (cta) {
                        return (
                            <div
                                className={classnames(styles.wrapperLink, {
                                    [styles.wrapperGrid1]: isSingleCard,
                                    [styles.wrapperGrid2]: isDoubleCard,
                                    [styles.wrapperGrid3]: isTripleCard,
                                })}
                                key={id}
                                data-gtm-id={dataGtmId}
                            >
                                <div className={classnames(styles.card, { backgroundColor: !!backgroundColor })}>
                                    {CardContent}
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div className={classnames(styles.card, { backgroundColor: !!backgroundColor })} key={id}>
                            {CardContent}
                        </div>
                    );
                })}
                <style jsx>{`
                    .backgroundColor {
                        background: #${backgroundColor};
                    }

                    .titleColor {
                        color: #${titleColor};
                    }

                    .descriptionColor {
                        color: #${descriptionColor};
                    }
                `}</style>
            </div>
        </div>
    );
};

export default ImageBlock;
