/**@namespace cardBalance*/

import { GetBalanceRequest, IGiftCardBalanceResponseModel, WebExperienceApi } from '../../@generated/webExpApi';
import createErrorWrapper from './createErrorWrapper';

const expApi = new WebExperienceApi();

const getBalanceApi = createErrorWrapper<IGiftCardBalanceResponseModel, GetBalanceRequest>(
    'getCardBalance',
    expApi.getBalance.bind(expApi)
);

/**
 * @async
 * @method getCardBalance
 * @param {string}  cardNumber card number digits
 * @param {string} cardPin card pin digits
 * @author Daniel Ramos <dramos@inspirebrands.com>
 * @added 2022-09-08
 * @version 1.0
 * @memberOf  cardBalance
 * @returns {Promise<IGiftCardBalanceResponseModel>}  Promise of the object that contains balance details from the provided card
 * @example
 * const getFundsFromCard = async (): number => {
 *     const cardNumber = '4444 3333 2222 1111';
 *     const cardPin = '123';
 *     const cardBalance = await getCardBalance(cardNumber, cardPin);
 *     return cardBalance.balance;
 * }
 */
export const getCardBalance = async (cardNumber: string, cardPin?: string): Promise<IGiftCardBalanceResponseModel> => {
    const getBalanceRequest = {
        iGiftCardBalanceRequestModel: {
            cardNumber,
            cardPin,
        },
    } as GetBalanceRequest;

    return getBalanceApi(getBalanceRequest);
};
