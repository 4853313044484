import React, { FC } from 'react';
import { IProduct } from '../../../@generated/@types/contentful';
import { ItemModel } from '../../../@generated/webExpApi';

import { InspireButton, InspireButtonType } from '../../atoms/button';
import Loader from '../../atoms/Loader';

export interface IProductItemCTAProps {
    isOrderAheadAvailable: boolean;
    productDetails: ItemModel;
    isSaleable: boolean;
    isAvailable: boolean;
    product: IProduct;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    gtmId?: string;
    buttonType?: InspireButtonType;
    isLoading?: boolean;
}

const ProductItemCTA: FC<IProductItemCTAProps> = (props) => {
    const {
        onClick,
        isOrderAheadAvailable,
        product,
        productDetails,
        isSaleable,
        isAvailable,
        gtmId,
        buttonType = 'primary',
        isLoading = false,
    } = props;

    const labels = {
        VIEW_ITEM: 'VIEW ITEM',
        ADD_TO_BAG: 'ADD TO BAG',
    };

    if (isLoading) {
        return (
            <span>
                <Loader size={20} />
            </span>
        );
    }

    if (!isSaleable || !isAvailable || !isOrderAheadAvailable) {
        return <InspireButton link={product} text={labels.VIEW_ITEM} type={buttonType} gtmId={gtmId} />;
    }

    const productHasModifiers = productDetails?.itemModifierGroups?.length;

    if (!productHasModifiers) {
        return <InspireButton onClick={onClick} text={labels.ADD_TO_BAG} type={buttonType} gtmId={gtmId} />;
    }

    return <InspireButton onClick={onClick} text={labels.ADD_TO_BAG} type={buttonType} gtmId={gtmId} />;
};

export default ProductItemCTA;
