import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import {
    ILocationByStateOrProvinceDetailsModel,
    ILocationWithDetailsModel,
} from '../../../@generated/webExpApi/models';
import { useLocationViewMenuCTA } from '../../../common/hooks/useLocationViewMenuCTA';
import usePickupLocationsSearch from '../../../common/hooks/usePickupLocationsSearch';
import { useConfiguration } from '../../../redux/hooks';

interface IUseNearbyLocationsSectionProps {
    locationDetails?: ILocationByStateOrProvinceDetailsModel;
    locationsLimit: string;
}

interface IUseNearbyLocationsSection {
    nearbyLocations: ILocationWithDetailsModel[];
    isOAEnabled: boolean;
    onOrderClick: (id?: string) => void;
    onViewMenuClick: (id?: string) => void;
}

export const useNearbyLocationsSection = ({
    locationDetails,
    locationsLimit,
}: IUseNearbyLocationsSectionProps): IUseNearbyLocationsSection => {
    const { searchLocationsByCoordinates, locationsSearchResult } = usePickupLocationsSearch();
    const router = useRouter();
    const onViewMenuClick = useLocationViewMenuCTA();

    const {
        configuration: { isOAEnabled },
    } = useConfiguration();

    const onOrderClick = (id: string) => {
        router.push(`/?locationId=${id}`);
    };

    useEffect(() => {
        searchLocationsByCoordinates({
            lat: locationDetails?.details?.latitude,
            lng: locationDetails?.details?.longitude,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nearbyLocations = useMemo(() => {
        if (!locationDetails) {
            return [];
        }
        return (locationsSearchResult?.locations || [])
            .filter((l) => l.id !== locationDetails?.id)
            .slice(0, parseInt(locationsLimit));
    }, [locationDetails, locationsSearchResult, locationsLimit]);

    return { nearbyLocations, isOAEnabled, onOrderClick, onViewMenuClick };
};
