import React, { FC } from 'react';
import classNames from 'classnames';
import LocationNewsTile from './locationNewsTile';
import { ILocationNewsSection } from '../../../@generated/@types/contentful';
import styles from './locationNewsSection.module.css';
import Accordion from '../../atoms/accordion';

import { ISectionComponentProps } from '..';
import withSectionLayout from '../withSectionLayout';

interface ILocationNewsSectionProps extends ISectionComponentProps {
    entry: ILocationNewsSection;
    data?: {
        expandedSections?: string[];
    };
}

const LocationNewsSection: FC<ILocationNewsSectionProps> = (props): JSX.Element => {
    const { header, description, tiles } = props.entry?.fields;
    const expandedSections = props.data?.expandedSections;
    const sectionId = props.entry?.sys.id;
    const isExpandedByDefault = !!(expandedSections && sectionId && expandedSections.find((x) => x === sectionId));

    const renderAccordionHeaderContent = (isExpanded, renderButton, onClickHandler) => {
        return (
            <div
                className={classNames(styles.accordion, {
                    [styles.expandedAccordion]: isExpanded,
                })}
                onClick={onClickHandler}
            >
                <p className={classNames('t-header-h3', styles.header)}>{header} </p>
                {renderButton()}
            </div>
        );
    };

    const renderAccordionContent = (isExpanded) => {
        return (
            <div
                className={classNames({
                    [styles.expanded]: isExpanded,
                    [styles.collapsed]: !isExpanded,
                })}
            >
                {description && <p className={classNames('t-paragraph-strong', styles.subHeader)}>{description}</p>}
                <div className={styles.container}>
                    {tiles?.length > 0 &&
                        tiles.map(({ fields: { header, description, icon }, sys: { id } }) => (
                            <LocationNewsTile key={id} header={header} description={description} icon={icon} />
                        ))}
                </div>
            </div>
        );
    };

    return (
        <Accordion
            renderHeader={(isExpanded, renderButton, onClickHandler) =>
                renderAccordionHeaderContent(isExpanded, renderButton, onClickHandler)
            }
            renderContent={(isExpanded) => renderAccordionContent(isExpanded)}
            containerClassName={styles.wrapper}
            isExpandedByDefault={isExpandedByDefault}
            iconClassName={styles.arrowIcon}
            title={header}
        />
    );
};

export default withSectionLayout(LocationNewsSection, {
    className: styles.containerSection,
});
