import React, { useMemo } from 'react';
import Loader from '../Loader';
import TextWithDotSeparator from '../TextWithDotSeparator';
import { formatOfferPrice, formatPrice } from '../../../lib/domainProduct';
import getDisplayCalories from './getDisplayCalories';
import classnames from 'classnames';
import styles from './productItemPriceAndCalories.module.css';
import { isCaloriesDisplayEnabled } from '../../../lib/getFeatureFlags';

interface IProductItemPriceAndCalories {
    price?: number;
    offerPrice?: number;
    calories?: number;
    calorieRange?: { min: string; max: string };
    gtmId?: string;
    isLoading?: boolean;
    className?: string;
    withPlus?: boolean;
    additionalText?: string;
    isSmallVariant?: boolean;
    getCaloriesInfoText?: (calories?: number) => string;
}

const ProductItemPriceAndCalories = ({
    isLoading,
    calories,
    calorieRange,
    gtmId,
    className,
    price,
    offerPrice,
    withPlus,
    additionalText,
    isSmallVariant,
    getCaloriesInfoText = getDisplayCalories,
}: IProductItemPriceAndCalories): JSX.Element => {
    const ariaLabelContent = isCaloriesDisplayEnabled() ? 'Product Price and Calories' : 'Product Price';

    const leftText = useMemo(() => {
        const formattedRegularPrice = Number.isFinite(price) ? formatPrice(price) : null;
        const formattedOfferPrice = Number.isFinite(offerPrice) ? formatOfferPrice(offerPrice) : null;

        if (formattedOfferPrice) {
            return (
                <div className={styles.priceWithDiscount}>
                    <span className={styles.oldPrice} data-testid="product-item-price">
                        {formattedRegularPrice}
                    </span>
                    &nbsp;
                    <span className={styles.discountedPrice} data-testid="product-item-offer-price">
                        {formattedOfferPrice}
                    </span>
                </div>
            );
        }

        if (formattedRegularPrice) {
            return (
                <span data-testid="product-item-price">
                    {withPlus ? `+${formattedRegularPrice}` : formattedRegularPrice}
                </span>
            );
        }

        return null;
    }, [offerPrice, price, withPlus]);

    const rightText = useMemo(() => {
        if (isCaloriesDisplayEnabled()) {
            if (calorieRange) {
                return (
                    <span data-testid="product-item-calories">{`${calorieRange.min} - ${calorieRange.max} cal`}</span>
                );
            }
            return <span data-testid="product-item-calories">{getCaloriesInfoText(calories)}</span>;
        }
        return null;
    }, [calorieRange, calories, getCaloriesInfoText]);

    const texts = useMemo(() => {
        return [leftText, rightText, isSmallVariant ? null : additionalText].filter((item) => {
            if (typeof item === 'string') {
                return !!item;
            }
            return !!item?.props.children;
        });
    }, [isSmallVariant, additionalText, leftText, rightText]);

    if (isLoading) {
        return (
            <span className={classnames(className, styles.wrapper)} data-gtm-id={gtmId} aria-label={ariaLabelContent}>
                <TextWithDotSeparator leftText={<Loader size={10} />} rightText={<Loader size={10} />} />
            </span>
        );
    }

    return (
        <div className={classnames(className, styles.wrapper)} data-gtm-id={gtmId}>
            <TextWithDotSeparator texts={texts} />
            {isSmallVariant && additionalText && <div className={styles.bottomText}>{additionalText}</div>}
        </div>
    );
};

export default ProductItemPriceAndCalories;
