import React, { FC, useEffect, useState, FormEvent } from 'react';
import { ILocalTapList } from '../../../@generated/@types/contentful';
import sectionIndentsStyles from '../sectionIndents.module.css';
import classnames from 'classnames';
import SectionHeader from '../../atoms/sectionHeader';
import styles from './localTapList.module.css';
import { InspireTabSet, InspireTab, InspireTabPanel } from '../../atoms/tabset';
import useLocalTapList from '../../../redux/hooks/useLocalTapList';
import { BEER_TYPE_INFO, BEER_TYPE_ORDER } from './constants';
import { IBeerModel, IBeersModel, ILocationByStateOrProvinceDetailsModel } from '../../../@generated/webExpApi';
import { getBeerMenuByLocationId } from '../../../common/services/localTapList';
import BrandLoader from '../../atoms/BrandLoader';
import { getBeersByType } from '../../../common/helpers/getBeersByType';
import ContentfulImage from '../../atoms/ContentfulImage';

interface ILocalTapListProps {
    entry: ILocalTapList;
    data?: {
        locationDetails?: ILocationByStateOrProvinceDetailsModel;
    };
}

const beerTypeComparator = (a: string, b: string) => {
    const i1 = BEER_TYPE_ORDER.indexOf(a);
    const i2 = BEER_TYPE_ORDER.indexOf(b);
    return (i1 > -1 ? i1 : Infinity) - (i2 > -1 ? i2 : Infinity);
};

const LocalTapList: FC<ILocalTapListProps> = (props) => {
    const {
        entry: {
            fields: { name, title, description, image, hasSectionIndents },
        },
    } = props;
    const locationDetails = props.data?.locationDetails;
    const { list, available } = useLocalTapList();
    const [tapList, setTapList] = useState<{ [key: string]: IBeersModel }>(list);
    const [tapListAvailable, setTapAvailable] = useState<boolean>(available);
    const [isLoading, setIsloading] = useState<boolean>(false);

    useEffect(() => {
        if (locationDetails) {
            setTapAvailable(false);
            setIsloading(true);
            getBeerMenuByLocationId(locationDetails.id)
                .then((data) => {
                    const locationTapList = getBeersByType(data);
                    setTapList(locationTapList);
                    setTapAvailable(!!Object.keys(locationTapList).length);
                })
                .catch(() => {
                    setTapList({});
                })
                .finally(() => {
                    setIsloading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [activeTab, setActiveTab] = useState<number>(0);

    const types = Object.keys(tapList).sort(beerTypeComparator);

    const formatCalories = (caloriesBySize: IBeerModel['caloriesBySize']) => {
        const { REGULAR, TALL } = caloriesBySize;
        const caloriesKey = Object.keys(caloriesBySize)[0];

        if (REGULAR && TALL) {
            return `${REGULAR} cal | ${TALL} cal`;
        } else if (REGULAR || TALL) {
            return `${REGULAR || TALL} cal`;
        } else if (caloriesKey) {
            return (
                <>
                    <span>{caloriesBySize[caloriesKey]} cal</span>
                    <span className={styles.caloriesAvailable}>Available in {caloriesKey} only</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={classnames('t-paragraph-hint', styles.caloriesUnavailable)}>
                        Calorie info unavailable
                    </span>
                    <span className={classnames('t-paragraph-hint', styles.caloriesUnavailableMobile)}>
                        Cal info unavailable
                    </span>
                </>
            );
        }
    };

    if (!tapListAvailable) return <></>;

    if (isLoading) {
        return <BrandLoader className={styles.loader} />;
    }

    return (
        <div className={classnames(styles.wrapper, { [sectionIndentsStyles.wrapper]: hasSectionIndents ?? true })}>
            {name && (
                <SectionHeader text={name} textClassName="t-header-h2" showBullet className={styles.sectionHeader} />
            )}
            <div className={styles.content}>
                <div className={styles.overview}>
                    <ContentfulImage asset={image} maxWidth={500} imageAlt={title} />
                    <h1 className="t-header-h1">{title}</h1>
                    <p className="t-paragraph">{description}</p>
                </div>

                <div className={styles.tabs}>
                    <div className={styles.tabsHeader}>
                        <InspireTabSet
                            variant="fullWidth"
                            value={activeTab}
                            className={styles.tabSet}
                            TabIndicatorProps={{ className: styles.tabIndicator }}
                            onChange={(_: FormEvent<HTMLButtonElement>, newValue?: number) => setActiveTab(newValue)}
                        >
                            {types.map((type) => (
                                <InspireTab
                                    key={type}
                                    label={BEER_TYPE_INFO[type]?.title || type}
                                    icon={BEER_TYPE_INFO[type]?.icon}
                                    className={styles.tab}
                                />
                            ))}
                        </InspireTabSet>
                        <span className={classnames('t-paragraph-hint', styles.sizeHeader)}>Regular | Tall</span>
                    </div>

                    <div className={classnames('t-paragraph-hint', styles.mobileSizeHeader)}>Regular | Tall</div>

                    {types.map((type, i) => (
                        <InspireTabPanel key={type} value={activeTab} index={i}>
                            <div className={styles.beerList}>
                                {tapList[type].items.map((beerInfo, i) => {
                                    return (
                                        <div key={i} className={styles.beerListRow}>
                                            <span className={'t-paragraph'}>{beerInfo.name}</span>
                                            <span className={classnames('t-paragraph', styles.sizeValue)}>
                                                {formatCalories(beerInfo.caloriesBySize)}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </InspireTabPanel>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocalTapList;
