import { LocationWithDetailsModel } from '../../../common/services/locationService/types';

export enum EmailSignupState {
    SIGNUP_FORM_SHOWED,
    SUCCESSFULLY_SUBMITTED,
    DUPLICATE_EMAIL_ERROR,
}

export interface ISignUpForm {
    firstName: string;
    lastName: string;
    email: string;
    zipCode: string;
    location?: LocationWithDetailsModel;
    dateOfBirth: string;
}
