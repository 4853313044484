import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import classnames from 'classnames';

import { IMarkup } from '../../@generated/@types/contentful';
import { ISectionComponentProps } from '.';

import sectionIndentsStyles from './sectionIndents.module.css';
import styles from './markup.module.css';
import useRichTextOptions from '../../common/hooks/useRichTextOptions';

interface IMarkupProps extends ISectionComponentProps {
    entry: IMarkup;
    className: string;
    classes?: { [key: string]: { [key: string]: string } };
}

export default function Markup(props: IMarkupProps): JSX.Element {
    const { entry, className } = props;
    const richTextOptions = useRichTextOptions();
    const classes = props.classes?.markup;

    return (
        <div
            className={classnames(sectionIndentsStyles.wrapper, {
                [className]: !!className,
                [classes?.markupWrapper]: !!classes?.markupWrapper,
                [styles.markup]: !classes?.markupWrapper,
            })}
        >
            <div
                className={classes?.markupSectionText}
                dangerouslySetInnerHTML={{ __html: documentToHtmlString(entry.fields.markup, richTextOptions) }}
            />
        </div>
    );
}
