import React, { FC, useCallback, useState } from 'react';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import { IVideoBlock } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';
import styles from './videoBlock.module.css';

interface IVideoBlockProps extends ISectionComponentProps {
    entry: IVideoBlock;
    className?: string;
    width?: string;
    height?: string;
}

const VideoBlock: FC<IVideoBlockProps> = ({ entry, className, width = '100%', height = '100%' }) => {
    const { name, webM, mp4, externalVideo, poster, controls, autoPlay, muted, loop } = entry.fields;

    const webMUrl = webM?.fields.file.url;
    const mp4Url = mp4?.fields.file.url;
    const externalVideoUrl = externalVideo?.fields.nameInUrl;
    const posterUrl = poster?.fields.file.url;

    const [playing, setPlaying] = useState(autoPlay);
    const handleClickPreview = useCallback(() => {
        setPlaying(true);
    }, []);

    // ReactPlayer also supports simple videos, but has a different behaviour with poster
    // For our goals (background video) is better to use our own implementation
    if (webMUrl || mp4Url) {
        return (
            <section className={styles.section}>
                <video
                    className={classnames(className || styles.videoSection)}
                    poster={posterUrl}
                    controls={controls}
                    autoPlay={autoPlay}
                    muted={muted}
                    loop={loop}
                    data-title={name}
                >
                    {!!webMUrl && <source src={webMUrl} type="video/webm" />}
                    {!!mp4Url && <source src={mp4Url} type="video/mp4" />}
                </video>
            </section>
        );
    }

    if (externalVideoUrl) {
        return (
            <section className={styles.section}>
                <div className={classnames(styles.playerWrapper, styles.videoSection)}>
                    <ReactPlayer
                        className={styles.reactPlayer}
                        url={webMUrl || mp4Url || externalVideoUrl}
                        playing={playing}
                        config={{
                            youtube: {
                                playerVars: { autoPlay: autoPlay ? 1 : 0, enablejsapi: 1 },
                            },
                        }}
                        muted={muted}
                        controls={controls}
                        loop={loop}
                        light={posterUrl}
                        onClickPreview={handleClickPreview}
                        width={width}
                        height={height}
                    />
                </div>
            </section>
        );
    }

    return null;
};

export default VideoBlock;
