import { useDomainProduct, useProducts } from '../../../redux/hooks/domainMenu';
import { checkIsUnavailableProductByCategories } from '../../helpers/bagHelper';
import { checkProductIsAvailable } from '../../helpers/checkProductIsAvailable';
import { useUnavailableCategories } from '../useUnavailableCategories';
import { SHOULD_CHECK_CHILD_ITEMS } from './constants';

export interface IUseProductIsAvailable {
    isAvailable: boolean;
}

export const useProductIsAvailable = (productId: string, category?: string): IUseProductIsAvailable => {
    const unavailableCategories = useUnavailableCategories();
    const product = useDomainProduct(productId);
    const domainProducts = useProducts();
    const isCombo = !!product?.hasChildItems;

    const isProductAvailable = checkProductIsAvailable(product);

    const isAvailable =
        !unavailableCategories.includes(category) &&
        !checkIsUnavailableProductByCategories(product?.categoryIds, unavailableCategories) &&
        isProductAvailable;

    if (SHOULD_CHECK_CHILD_ITEMS && isCombo) {
        const eachChildAvailable = product.itemModifierGroups.every((img) =>
            Object.values(img.itemModifiers).some((im) => {
                if (im.itemId) {
                    return checkProductIsAvailable(domainProducts[im.itemId]);
                }
                return im.itemModifiers.some((im) => checkProductIsAvailable(domainProducts[im.itemId]));
            })
        );

        return {
            isAvailable: isAvailable && eachChildAvailable,
        };
    }

    return {
        isAvailable,
    };
};
