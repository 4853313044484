export enum MobileOperatingSystem {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}

export const getMobileOperatingSystem = (): MobileOperatingSystem | null => {
    // @ts-ignore
    const userAgent = typeof window !== 'undefined' && (navigator.userAgent || navigator.vendor || window.opera);

    if (/android/i.test(userAgent)) {
        return MobileOperatingSystem.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return MobileOperatingSystem.IOS;
    }

    return null;
};
