import React, { FC } from 'react';
import ContentfulImage from '../../atoms/ContentfulImage';

import styles from './recentOrdersHeader.module.css';
import classnames from 'classnames';
import { InspireLink } from '../../atoms/link';
import { Asset } from 'contentful';

interface IRecentOrdersHeaderProps {
    image: Asset;
    mainText?: string;
    secondaryText?: string;
}

const RecentOrdersHeader: FC<IRecentOrdersHeaderProps> = ({ image, mainText, secondaryText }): JSX.Element => {
    return (
        <div className={styles.container}>
            <ContentfulImage className={styles.image} asset={image} maxWidth={50} />
            <div className={styles.textWithCTABlock}>
                <div className={styles.textBlock}>
                    <span className={classnames('t-subheader-small', styles.subheader)}>
                        {mainText || 'RELIVE THE GLORY'}
                    </span>
                    <span className="t-header-card-title">{secondaryText || 'RECENT ORDERS'}</span>
                </div>
                <InspireLink link="/account/orders" type="secondary" className={styles.viewAll}>
                    View All
                </InspireLink>
            </div>
        </div>
    );
};

export default RecentOrdersHeader;
