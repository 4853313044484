import { useRouter } from 'next/router';
import { useDomainMenu, useOrderLocation, useTallyOrder } from '../../redux/hooks';
import { getLocationById } from '../services/locationService';
import { useLocationUnavailableError } from './useLocationUnavailableError';

export const useLocationViewMenuCTA = () => {
    const router = useRouter();
    const {
        actions: { setPickupLocation },
    } = useOrderLocation();
    const {
        actions: { getDomainMenu },
    } = useDomainMenu();
    const { setUnavailableTallyItems } = useTallyOrder();
    const { pushLocationUnavailableError } = useLocationUnavailableError();

    const onViewMenuClick = async (id: string) => {
        const location = await getLocationById({
            locationId: id,
        });

        if (location && !location.isDigitallyEnabled) {
            pushLocationUnavailableError(location);
        }
        if (location) {
            setPickupLocation(location);
            getDomainMenu(location);
            setUnavailableTallyItems([]);
            router.push('/menu');
        }
    };

    return onViewMenuClick;
};
