import React, { FC } from 'react';
import styles from './numberedList.module.css';
import SectionHeader from '../../atoms/sectionHeader';
import classnames from 'classnames';
import TextWithTrademark from '../../atoms/textWithTrademark';
import { INumberedListData } from './numberedListSection';

export enum NumberedListVariantsEnum {
    STYLIZED = 'stylized',
}

interface INumberedListProps {
    data: INumberedListData;
    variant?: NumberedListVariantsEnum;
    withoutShadow?: boolean;
}

const NumberedList: FC<INumberedListProps> = ({ data, variant, withoutShadow }) => {
    const { listItems, listDescription, listTitle } = data;
    return (
        <div className={styles.wrapper}>
            {listTitle && <SectionHeader text={listTitle} className={styles.title} />}
            <div
                className={classnames(styles.listWrapper, styles[variant] || '', {
                    [styles.withoutShadow]: withoutShadow,
                })}
            >
                {listDescription && (
                    <TextWithTrademark tag="p" text={listDescription} className={classnames(styles.description)} />
                )}
                <ol className={styles.list}>
                    {listItems?.length > 0 &&
                        listItems.map(({ itemTitle, itemDescription }) => (
                            <li key={itemTitle} className={styles.listItem}>
                                <div className={styles.itemTitle}>
                                    <TextWithTrademark
                                        tag="p"
                                        text={itemTitle}
                                        className={classnames({
                                            ['t-subheader-small']: variant === NumberedListVariantsEnum.STYLIZED,
                                        })}
                                    />
                                </div>
                                <TextWithTrademark
                                    tag="p"
                                    text={itemDescription}
                                    className={classnames(styles.itemDescription)}
                                />
                            </li>
                        ))}
                </ol>
            </div>
        </div>
    );
};

export default NumberedList;
