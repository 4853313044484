import React from 'react';

import styles from './withAccordion.module.css';
import CollapseItem from './collapseItem';
import { IFrequentlyAskedQuestions } from '../../../../@generated/@types/contentful';

export default function FaqWithAccardion(props: IFrequentlyAskedQuestions): JSX.Element {
    const items = props.fields.items || [];

    return (
        <div className={styles.container}>
            {items.map((item) => (
                <CollapseItem key={item.sys.id} title={item.fields.title} items={item.fields.items} />
            ))}
        </div>
    );
}
