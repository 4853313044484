import { useLdp } from '../../redux/hooks';

import Mustache from 'mustache';

import { ILocationByStateOrProvinceDetailsModel } from '../../@generated/webExpApi';

interface ILocationPageDetailsVariables {
    line1: string;
    city: string;
    stateProvinceCode: string;
}
interface IContentfullVariables {
    locationDetailsPage: ILocationPageDetailsVariables;
}

export const getContentfulVariables = (
    locationDetails: ILocationByStateOrProvinceDetailsModel
): IContentfullVariables => {
    const address = locationDetails?.contactDetails?.address;

    if (address) {
        return {
            locationDetailsPage: {
                line1: address.line,
                city: address.cityName,
                stateProvinceCode: address.stateProvinceCode,
            },
        };
    }

    return null;
};

export const useContentfulTemplatedText = () => {
    const { locationDetails } = useLdp();
    const data = getContentfulVariables(locationDetails);

    return (template: string): string => Mustache.render(template, data);
};
