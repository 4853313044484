import { IOrderItemProduct } from '../../hooks/useOrderItem';
import { checkProductIsAvailable } from '../checkProductIsAvailable';

interface IGetProductsAvailability {
    areAllProductsUnavailable: boolean;
    areSomeProductsUnavailable: boolean;
}

/**
 * @method getProductsAvailability
 * @param {IOrderItemProduct} products order domain products
 * @param {boolean} [checkIsSaleable] check isSaleable field
 * @author Andrei Shubich <ashubich@inspirebrands.com>
 * @added 2023-10-03
 * @version 1.0
 * @returns {IGetProductsAvailability} areAllProductsUnavailable, areSomeProductsUnavailable
 */
export const getProductsAvailability = (
    products: IOrderItemProduct[],
    checkIsSaleable?: boolean
): IGetProductsAvailability => {
    const unavailableProducts = products.filter((product) => {
        const domainProductIsAvailable = checkProductIsAvailable(product.domainProduct);
        const domainProductIsSaleable = product.domainProduct?.isSaleable;

        if (product.isCombo) {
            return (
                !domainProductIsAvailable ||
                (checkIsSaleable && !domainProductIsSaleable) ||
                product.childDomainProducts.some((item) => {
                    return !checkProductIsAvailable(item) || (checkIsSaleable && !item?.isSaleable);
                })
            );
        }
        return !domainProductIsAvailable || (checkIsSaleable && !domainProductIsSaleable);
    });

    return {
        areAllProductsUnavailable: products.length === unavailableProducts.length,
        areSomeProductsUnavailable: unavailableProducts.length > 0,
    };
};
