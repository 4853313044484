import React, { useCallback } from 'react';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { IMainProductBannerFields } from '../../../@generated/@types/contentful';

import { ProductItemPriceAndCalories } from '../../atoms/productItemPriceAndCalories';
import ContentfulImage from '../../atoms/ContentfulImage';
import { InspireButton } from '../../atoms/button';
import { InspireLink } from '../../atoms/link';
import TextWithTrademark from '../../atoms/textWithTrademark';
import { useProductOrderAheadAvailability } from '../../../common/hooks/useProductOrderAheadAvailability';
import { useLocationOrderAheadAvailability } from '../../../common/hooks/useLocationOrderAheadAvailability';
import { useDiscountPriceAndCalories, useDomainProductByContentfulFields } from '../../../redux/hooks/domainMenu';
import { useBag, useGlobalProps, useDomainMenu } from '../../../redux/hooks';
import { TYPOGRAPHY_CLASS } from '../mainBanner/constants';

import { getGtmIdByName } from '../../../lib/gtm';

import styles from '../mainBanner/mainBanner.module.css';
import { useProductIsSaleable } from '../../../common/hooks/useProductIsSaleable';
import { useProductIsAvailable } from '../../../common/hooks/useProductIsAvailable';

import ProductItemCTA from './productItemCTA';

export default function StaticProductBanner(props: IMainProductBannerFields): JSX.Element {
    const {
        backgroundColorRef,
        bottomText,
        productLink,
        backupLinkCTA,
        mainText,
        mainTextLink,
        rightImage,
        rightImageLink,
        topIcon,
        topText,
    } = props;

    const { name } = productLink.fields;

    const isSmallVariant = useMediaQuery('(max-width: 1024px)');
    const bag = useBag();
    const product = useDomainProductByContentfulFields(productLink.fields);

    const productId = product?.id;
    const { isOrderAheadAvailable } = useProductOrderAheadAvailability(productId);
    const { isLocationOrderAheadAvailable } = useLocationOrderAheadAvailability();
    const globalProps = useGlobalProps();
    const { price, calories } = useDiscountPriceAndCalories(productId);
    const { isSaleable } = useProductIsSaleable(productId);
    const { isAvailable } = useProductIsAvailable(productId);

    const productDetailsPagePaths = globalProps?.productDetailsPagePaths || [];

    const { menuCategoryUrl: category } =
        productDetailsPagePaths.find((path) => path.productIds.includes(productId)) || {};
    const backgroundColor = backgroundColorRef?.fields?.hexColor;
    const productName = product?.name || name || '';
    const buttonType = isSmallVariant ? 'primary' : 'large';
    const gtmId = getGtmIdByName('mainProductBanner', productName);

    const handleAddToBag = useCallback(() => {
        bag.actions.addDefaultToBag({ productId, name: productName, category });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, bag.bagEntries]);
    const { loading } = useDomainMenu();

    return (
        <div data-gtm-id={gtmId} role="banner" className={classNames('mainBannerConf', styles.mainBanner)}>
            <div data-gtm-id={gtmId} className={styles.actionsSection}>
                <div data-gtm-id={gtmId} className={styles.topIconBox}>
                    {topIcon && (
                        <div data-gtm-id={gtmId} className={styles.topIconSection}>
                            <ContentfulImage asset={topIcon} className={styles.topIcon} />
                        </div>
                    )}
                    {topText && (
                        <TextWithTrademark
                            tag="span"
                            text={topText}
                            data-gtm-id={gtmId}
                            className={classNames('t-subheader-small', styles.topIconText)}
                        />
                    )}
                </div>
                <InspireLink link={mainTextLink || productLink}>
                    <TextWithTrademark
                        tag="h2"
                        text={mainText || productName}
                        data-gtm-id={gtmId}
                        className={TYPOGRAPHY_CLASS.MAIN_TEXT}
                    />
                </InspireLink>
                <TextWithTrademark
                    tag="p"
                    text={bottomText}
                    data-gtm-id={gtmId}
                    className={classNames('t-paragraph', styles.bottomText)}
                />
                <ProductItemPriceAndCalories
                    isLoading={loading}
                    gtmId={gtmId}
                    price={isOrderAheadAvailable ? price : null}
                    calories={calories}
                    className={classNames('t-paragraph-hint', styles.caloriesAndPrice)}
                />
                {isLocationOrderAheadAvailable ? (
                    <ProductItemCTA
                        isOrderAheadAvailable={isOrderAheadAvailable}
                        productDetails={product}
                        isSaleable={isSaleable}
                        isAvailable={isAvailable}
                        product={productLink}
                        onClick={handleAddToBag}
                        gtmId={gtmId}
                        buttonType={buttonType}
                        isLoading={loading}
                    />
                ) : (
                    <InspireButton
                        text="View Item"
                        type={buttonType}
                        gtmId={gtmId}
                        link={productLink ? productLink : backupLinkCTA}
                    />
                )}
            </div>
            <div data-gtm-id={gtmId} className={styles.imageSection}>
                <InspireLink link={rightImageLink || productLink} className={styles.topLink}>
                    <ContentfulImage gtmId={gtmId} asset={rightImage || productLink.fields.image} maxWidth={650} />
                </InspireLink>
            </div>
            <style jsx>{`
                .mainProductBannerConf {
                    background-color: ${backgroundColor ? `#${backgroundColor}` : 'transparent'};
                }
            `}</style>
        </div>
    );
}
