import React, { FC, forwardRef, RefObject } from 'react';
import { IDeliveryPartners } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';
import DeliveryPartners from './deliveryPartners';

import { useLdp } from '../../../redux/hooks';

export interface IDeliveryPartnersContainerProps extends ISectionComponentProps {
    entry: IDeliveryPartners;
    classes?: { [key: string]: { [key: string]: string } };
}

const DeliveryPartnersContainer: FC<IDeliveryPartnersContainerProps> = forwardRef<
    HTMLDivElement,
    IDeliveryPartnersContainerProps
>(({ entry, classes }, ref: RefObject<HTMLDivElement>) => {
    const { locationDetails } = useLdp();

    const partners = locationDetails?.deliveryProviders;
    if (!partners?.length) {
        return null;
    }

    return (
        <div ref={ref}>
            <DeliveryPartners entry={entry} partners={partners} classes={classes} />
        </div>
    );
});

DeliveryPartnersContainer.displayName = 'DeliveryPartnersContainer';

export default DeliveryPartnersContainer;
