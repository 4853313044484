import { useOrderLocation } from '../../redux/hooks';
import { useDomainProduct } from '../../redux/hooks/domainMenu';
import { checkProductIsAvailable } from '../helpers/checkProductIsAvailable';
interface IUseProductOrderAheadAvailability {
    isOrderAheadAvailable: boolean;
}

export const useProductOrderAheadAvailability = (productId: string): IUseProductOrderAheadAvailability => {
    const { isCurrentLocationOAAvailable } = useOrderLocation();
    const product = useDomainProduct(productId);

    const isOrderAheadAvailable = !!(productId && checkProductIsAvailable(product) && isCurrentLocationOAAvailable);

    return { isOrderAheadAvailable };
};
