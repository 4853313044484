export const SHOULD_SHOW_TWO_BUTTONS = false;
export const SHOULD_SHOW_INFO_BLOCK = true;

export const TYPOGRAPHY_CLASS = {
    HEADER: 't-header-h1',
    CONTENT_HEADER: 't-header-h2',
    CONTENT_DESCRIPTION: '',
    PHONE_NUMBER: 't-subheader-small',
    TEXT_BLOCK: 't-subheader-small',
};
