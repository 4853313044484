import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { InspireButton } from '../../atoms/button';
import { ISingleImageWithOverlay } from '../../../@generated/@types/contentful';

import sectionIndentsStyles from '../sectionIndents.module.css';
import styles from './singleImageWithOverlay.module.css';
import ContentfulImage from '../../atoms/ContentfulImage';
import InspirePhoneLink from '../../atoms/phoneLink';
import TextWithTrademark from '../../atoms/textWithTrademark';

import { useAppDispatch } from '../../../redux/store';
import { SHOULD_SHOW_TWO_BUTTONS, SHOULD_SHOW_INFO_BLOCK, TYPOGRAPHY_CLASS } from './constants';
import { GTM_SEND_A_MESSAGE } from '../../../common/services/gtmService/constants';
import getBrandInfo from '../../../lib/brandInfo';
import { BlazingRewardsForm } from '../../organisms/blazingRewards/blazingRewardsForm';
import { BlazingRewardsButton } from '../../organisms/blazingRewards/blazingRewardsButton';
import LegalMessage from '../../atoms/legalMessage';

interface ISingleImageWithOverlayProps {
    entry: ISingleImageWithOverlay;
}
const HeaderTextSize = (headerTextSize): keyof JSX.IntrinsicElements => {
    let tag = 'h2' as keyof JSX.IntrinsicElements;
    if (headerTextSize === 'h1') {
        tag = 'h1';
    }
    return tag;
};

const SingleImageWithOverlay: FC<ISingleImageWithOverlayProps> = ({ entry: { fields } }) => {
    const dispatch = useAppDispatch();

    const [isBlazingRewardsFormOpen, setIsBlazingRewardsFormOpen] = useState(false);

    if (!fields) return null;

    const {
        header,
        contentHeader,
        contentDescription,
        textBlock1,
        textBlock1Value,
        textBlock2,
        textBlock2Value,
        buttonCta,
        secondaryButtonCta,
        phone,
        image,
        imageLeftAligned,
        showBlazingRewardsButton,
        legalMessage,
        headerTextSize,
    } = fields;

    const sectionClasses = classNames(sectionIndentsStyles.wrapper, styles.section, {
        [styles.imageLeftAligned]: !!imageLeftAligned,
    });

    const shouldShowTextBlock1 = textBlock1 || textBlock1Value;
    const shouldShowTextBlock2 = textBlock2 || textBlock2Value;
    const isShowInfoBlock = SHOULD_SHOW_INFO_BLOCK && (shouldShowTextBlock1 || shouldShowTextBlock2);

    const isShowBlazingRewardsButton = getBrandInfo().brandId === 'Bww' && showBlazingRewardsButton;
    const isShowSecondaryButton =
        !isShowBlazingRewardsButton && secondaryButtonCta && (SHOULD_SHOW_TWO_BUTTONS || !buttonCta);

    return (
        <section className={sectionClasses}>
            {header && (
                <TextWithTrademark
                    text={header}
                    tag={HeaderTextSize(headerTextSize)}
                    className={classNames(TYPOGRAPHY_CLASS.HEADER, styles.header)}
                />
            )}
            <div className={styles.content}>
                <div className={styles.textSection}>
                    <TextWithTrademark
                        text={contentHeader}
                        tag={header ? 'h3' : 'h2'}
                        className={classNames(TYPOGRAPHY_CLASS.CONTENT_HEADER, styles.contentHead)}
                    />
                    <TextWithTrademark
                        tag="p"
                        className={classNames(TYPOGRAPHY_CLASS.CONTENT_DESCRIPTION, styles.contentDescription)}
                        text={contentDescription}
                    />
                    {legalMessage && <LegalMessage className={styles.legalMessage} legalMessage={legalMessage} />}
                    {isShowInfoBlock && (
                        <div className={classNames(TYPOGRAPHY_CLASS.CONTENT_DESCRIPTION, styles.infoBlock)}>
                            {shouldShowTextBlock1 && (
                                <div>
                                    {textBlock1 && (
                                        <TextWithTrademark
                                            tag="span"
                                            text={textBlock1}
                                            className={classNames(TYPOGRAPHY_CLASS.TEXT_BLOCK, styles.textBlock)}
                                        />
                                    )}
                                    <TextWithTrademark
                                        tag="span"
                                        text={textBlock1Value}
                                        className={styles.textBlockValue}
                                    />
                                </div>
                            )}
                            {shouldShowTextBlock2 && (
                                <div>
                                    {textBlock2 && (
                                        <TextWithTrademark
                                            tag="span"
                                            text={textBlock2}
                                            className={classNames(TYPOGRAPHY_CLASS.TEXT_BLOCK, styles.textBlock)}
                                        />
                                    )}
                                    {textBlock2Value && (
                                        <TextWithTrademark
                                            tag="span"
                                            text={textBlock2Value}
                                            className={styles.textBlockValue}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <div className={styles.bottomBlock}>
                        {buttonCta && (
                            <InspireButton
                                className={styles.buttonCta}
                                onClick={() => dispatch({ type: GTM_SEND_A_MESSAGE })}
                                link={buttonCta}
                            />
                        )}
                        {isShowSecondaryButton && (
                            <InspireButton className={styles.secondaryButtonCta} link={secondaryButtonCta} />
                        )}
                        {isShowBlazingRewardsButton && (
                            <BlazingRewardsButton
                                className={styles.secondaryButtonCta}
                                onClick={() => setIsBlazingRewardsFormOpen(true)}
                            />
                        )}
                        {phone && (
                            <div className={styles.phoneBlock}>
                                <InspirePhoneLink
                                    className={classNames(TYPOGRAPHY_CLASS.PHONE_NUMBER, styles.phoneNumber)}
                                    phone={phone}
                                    iconClassName={styles.icon}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.imageSection}>
                    <ContentfulImage asset={image} maxWidth={660} />
                </div>
            </div>
            {isShowBlazingRewardsButton && (
                <BlazingRewardsForm
                    isOpen={isBlazingRewardsFormOpen}
                    onClose={() => {
                        setIsBlazingRewardsFormOpen(false);
                    }}
                />
            )}
        </section>
    );
};

export default SingleImageWithOverlay;
