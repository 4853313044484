/**
 * @namespace camelCase
 */

/**
 * @method camelCase
 * @memberOf camelCase
 * @description Converts `string` to `camelCase`.
 * @param {string} str The string to convert.
 * @returns {string} Returns the camel cased string.
 * @example
 *
 * camelCase('test example')
 * // => 'testExample'
 *
 * camelCase('--test-example--')
 * // => 'testExample'
 *
 */

export const camelCase = (str: string): string =>
    str
        .trim()
        .toLowerCase()
        .replace(/[-_\s]+(.)?/g, (_, char) => (char ? char.toUpperCase() : ''));
