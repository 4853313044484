import React from 'react';
import MainBanner from './mainBanner';
import { isMainRewardsBannerOn } from '../../../lib/getFeatureFlags';
import { useAccount, useBag, useOrderLocation, useRewards } from '../../../redux/hooks';
import { IMainBanner } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';
import MainDealsBanner from '../mainDealBanner/mainDealsBanner';
import { useBrazeIntegration } from '../../../common/hooks/braze/useBrazeIntegration';
import isMobileScreen from '../../../lib/isMobileScreen';
interface IMainBannerProps extends ISectionComponentProps {
    entry: IMainBanner;
}

export { ImageSection } from './mainBanner';

export default function MainBannerDefault(props: IMainBannerProps): JSX.Element {
    const { account } = useAccount();
    const { firstExpiringOffer, appliedPromocode } = useRewards();
    const { dealId } = useBag();
    const { isCurrentLocationOAAvailable, currentLocation } = useOrderLocation();
    const mobileScreen = isMobileScreen();
    const isLocationAvailable = isCurrentLocationOAAvailable && !currentLocation.isClosed;

    const { cards } = useBrazeIntegration();

    if (!account) {
        return <MainBanner {...props} />;
    }

    const isAvailableBanner = isMainRewardsBannerOn() && firstExpiringOffer && isLocationAvailable;
    const frontCard = cards.find((card) => card.extras?.isInFrontOfRewards?.toLowerCase().includes('true'));
    const [firstCard] = cards;
    const card = frontCard || (!isAvailableBanner && firstCard);

    const isShowBrazeCard = (mobileScreen && card) || card?.extras?.fullScreenImage;
    if (isShowBrazeCard) {
        const isCenteredButton = card.extras.type === 'secondary';

        return <MainDealsBanner offer={card} isBrazeBanner isCenteredButton={isCenteredButton} />;
    }

    if (isAvailableBanner) {
        const isRedeemed = dealId === firstExpiringOffer.id;

        return (
            <MainDealsBanner
                offer={firstExpiringOffer}
                isAnyRedeemed={!!dealId}
                isPromocodeApplied={!!appliedPromocode}
                isRedeemed={isRedeemed}
            />
        );
    }

    return null;
}
