import React from 'react';
import styles from './sectionHeader.module.css';
import classnames from 'classnames';

import TextWithTrademark from './textWithTrademark';
export interface ISectionHeaderProps {
    className?: string;
    textClassName?: string;
    showBullet?: boolean;
    text: string;
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}
export default function SectionHeader(props: ISectionHeaderProps): JSX.Element {
    return (
        <div className={classnames(styles.bulletHeaderContainer, props.className)}>
            {props.text && props.showBullet && (
                <div className={styles.boxBulletContainer}>
                    <div className={styles.boxBullet}></div>
                </div>
            )}
            {props?.text?.trim() && (
                <TextWithTrademark
                    tag={props.tag || 'h3'}
                    text={props.text}
                    className={classnames(props.textClassName || 't-header-h2', styles.headerText, {
                        [styles.headerTextWithoutBullet]: !props.showBullet,
                    })}
                    dataTestId={props.text}
                />
            )}
        </div>
    );
}

SectionHeader.defaultProps = {
    showBullet: true,
};
