import React from 'react';
import styles from './withoutAccordion.module.css';
import { IFrequentlyAskedQuestions } from '../../../../@generated/@types/contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import classNames from 'classnames';
import useRichTextOptions from '../../../../common/hooks/useRichTextOptions';

export default function FaqWithoutAccardion(props: IFrequentlyAskedQuestions): JSX.Element {
    const items = props.fields.items || [];
    const richTextOptions = useRichTextOptions();

    return (
        <div className={styles.container}>
            {items.reduce((acc, curr) => {
                const items = (curr && curr?.fields?.items) || [];
                const result = items.map(({ fields, sys }) => {
                    return (
                        <div className={styles.subItem} key={sys.id}>
                            <div className={classNames('t-subheader', styles.heading)}>{fields.question}</div>
                            <div
                                className={classNames('t-paragraph', styles.answer)}
                                dangerouslySetInnerHTML={{
                                    __html: documentToHtmlString(fields.answer, richTextOptions),
                                }}
                            />
                        </div>
                    );
                });

                return [...acc, ...result];
            }, [])}
        </div>
    );
}
