import React, { FC } from 'react';
import classnames from 'classnames';
import { IPromoBlockCard } from '../../../@generated/@types/contentful';
import { InspireButton } from '../../atoms/button';
import { InspireLink } from '../../atoms/link';
import ContentfulImage from '../../atoms/ContentfulImage';
import LegalMessage from '../../atoms/legalMessage';
import { spaces2underscores } from '../../../lib/gtm';
import styles from './promoBlock.module.css';
import ContentfulBackgroundImageContainer from '../../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';

interface IPromoBlockCardProps extends IPromoBlockCard {
    titleColor: string;
    backgroundColor: string;
    descriptionColor: string;
}

export const PromoBlockCard: FC<IPromoBlockCardProps> = ({
    fields: { image, title, description, cta, backgroundColor: cardBackgroundColor, backgroundImage, legalMessage },
    titleColor,
    backgroundColor: sectionBackgroundColor,
    descriptionColor,
}) => {
    const backgroundColor = cardBackgroundColor ? cardBackgroundColor.fields.hexColor : sectionBackgroundColor;
    const dataGtmId = `promoBanner-${spaces2underscores(title)}`;

    let cardContent = (
        <div className={styles.cardWrapper} data-gtm-id={dataGtmId}>
            <ContentfulImage asset={image} className={styles.image} maxWidth={310} gtmId={dataGtmId} />
            <h2
                className={classnames('t-header-h1', styles.title, { titleColor: !!titleColor })}
                title={title}
                data-gtm-id={dataGtmId}
            >
                {title}
            </h2>
            {(description || legalMessage) && (
                <p
                    className={classnames('t-paragraph', 'truncate-at-3', styles.description, {
                        descriptionColor: !!descriptionColor,
                    })}
                    data-gtm-id={dataGtmId}
                >
                    {description && (
                        <span title={description} data-gtm-id={dataGtmId}>
                            {description}
                        </span>
                    )}
                    {legalMessage && (
                        <LegalMessage className={classnames(styles.legalMessage)} legalMessage={legalMessage} />
                    )}
                </p>
            )}
            {cta && <InspireButton text={cta.fields.name} type="primary" className={styles.cta} gtmId={dataGtmId} />}
        </div>
    );

    if (cta)
        cardContent = (
            <InspireLink link={cta.fields.link} gtmId={dataGtmId}>
                {cardContent}
            </InspireLink>
        );

    return (
        <ContentfulBackgroundImageContainer
            asset={backgroundImage ? backgroundImage : null}
            backgroundColor={backgroundColor ? backgroundColor : 'transparent'}
            containerClassName={styles.cardContainer}
        >
            <div
                className={classnames(styles.card, {
                    [styles.withCta]: !!cta,
                })}
                data-gtm-id={dataGtmId}
            >
                {cardContent}
                <style jsx>{`
                    .${styles.card} :global(.titleColor) {
                        color: #${titleColor};
                    }
                    .${styles.card} :global(.descriptionColor) {
                        color: #${descriptionColor};
                    }
                `}</style>
            </div>
        </ContentfulBackgroundImageContainer>
    );
};
