import React from 'react';
import { IBenefitsOfJoining } from '../../../@generated/@types/contentful';
import styles from './benefitsOfJoiningSection.module.css';
import classnames from 'classnames';
import SectionHeader from '../../atoms/sectionHeader';
import BenefitItem from './benefit';
import ContentfulImage from '../../atoms/ContentfulImage';

interface IBenefitsOfJoiningSection {
    entry: IBenefitsOfJoining;
}

const BenefitsOfJoiningSection = (props: IBenefitsOfJoiningSection): JSX.Element => {
    const { entry } = props;
    const { title, secondaryDescription, benefits, image, secondarySubTitle, secondaryTitle } = entry.fields;
    return (
        <div className={styles.sectionFlexContainer}>
            <SectionHeader showBullet text={title} tag="h2" textClassName={classnames('t-header-h2')} />
            <div className={styles.mainContent}>
                <div className={styles.column}>
                    {benefits.map((item) => (
                        <BenefitItem key={item.sys.id} item={item} />
                    ))}
                </div>
                <div className={classnames(styles.column, styles.infoColumn)}>
                    <ContentfulImage asset={image} />
                    <span className={classnames('t-subheader', styles.secondaryTitle)}>{secondaryTitle}</span>
                    <span className={classnames('t-header-h1', styles.secondarySubTitle)}>{secondarySubTitle}</span>
                    <p className={classnames('t-paragraph', styles.secondaryDescription)}>{secondaryDescription}</p>
                </div>
            </div>
        </div>
    );
};

export default BenefitsOfJoiningSection;
