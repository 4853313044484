import React, { useState } from 'react';

import classNames from 'classnames';
import styles from './withAccordion.module.css';

import Collapse from '@material-ui/core/Collapse';
import Icon from '../../../atoms/BrandIcon';
import { IFrequentlyAskedQuestionsLineItemsFields } from '../../../../@generated/@types/contentful';
import isSmallScreen from '../../../../lib/isSmallScreen';
import isMobileScreen from '../../../../lib/isMobileScreen';

import { useContentfulTemplatedText } from '../../../../common/hooks/useContentfulTemplatedText';
import useRichTextOptions from '../../../../common/hooks/useRichTextOptions';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default function CollapseItem(props: IFrequentlyAskedQuestionsLineItemsFields): JSX.Element | null {
    const [collapsed, setCollapsed] = useState(false);
    const smallScreen = isSmallScreen();
    const mobileScreen = isMobileScreen();
    const richTextOptions = useRichTextOptions();

    const { title, items } = props;

    const handleCollapsed = () => setCollapsed((collapsed) => !collapsed);
    const getContentfulTemplatedText = useContentfulTemplatedText();

    return items ? (
        <div
            className={classNames(styles.item, {
                [styles.line]: collapsed,
            })}
        >
            <div
                className={classNames(styles.itemSection, {
                    [styles.collapsedItemSection]: collapsed,
                })}
                key={title}
                onClick={handleCollapsed}
            >
                <span
                    className={classNames('t-subheader-universal', styles.heading, {
                        [styles.collapsedTitle]: collapsed,
                        't-subheader-universal-small': smallScreen || mobileScreen,
                    })}
                >
                    {title}
                </span>
                <Icon ariaLabel="collapse-icon" icon={collapsed ? 'direction-up' : 'direction-down'} role="img" />
            </div>
            <Collapse in={collapsed}>
                <div className={styles.subItemsContainer}>
                    {items.map((item) => (
                        <div className={styles.subItem} key={item.sys.id}>
                            <div className="t-paragraph-strong">{getContentfulTemplatedText(item.fields.question)}</div>
                            <div
                                className={classNames('t-paragraph', styles.answer)}
                                dangerouslySetInnerHTML={{
                                    __html: getContentfulTemplatedText(
                                        documentToHtmlString(item.fields.answer, richTextOptions)
                                    ),
                                }}
                            />
                        </div>
                    ))}
                </div>
            </Collapse>
        </div>
    ) : null;
}
