import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import StaticProductBanner from './staticProductBanner';
import MainDealsBanner from '../mainDealBanner/mainDealsBanner';
import { isAccountOn, isMainProductDealsBannerOn } from '../../../lib/getFeatureFlags';
import useAccount from '../../../redux/hooks/useAccount';
import { useRewards } from '../../../redux/hooks';

import { IMainProductBanner } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';

interface IMainProductBannerProps extends ISectionComponentProps {
    entry: IMainProductBanner;
}

export default function MainProductBanner(props: IMainProductBannerProps): JSX.Element {
    const fields = props.entry.fields;

    const { isAuthenticated, user, isLoading } = useAuth0();
    const { accountInfo } = useAccount();
    const { firstExpiringOffer } = useRewards();

    if (isAccountOn() && isMainProductDealsBannerOn() && isAuthenticated && user && firstExpiringOffer && !isLoading) {
        const userName = accountInfo?.firstName || user.given_name;
        return <MainDealsBanner userName={userName} offer={firstExpiringOffer} />;
    }

    return <StaticProductBanner {...fields} />;
}
