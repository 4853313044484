import React, { FC } from 'react';
import ContentfulImage from '../../../atoms/ContentfulImage';
import { Asset } from 'contentful';
import styles from './locationNewsTile.module.css';

interface LocationNewsTileProps {
    header?: string;
    description?: string;
    icon?: Asset;
}

const LocationNewsTile: FC<LocationNewsTileProps> = ({ header, description, icon }): JSX.Element => (
    <div className={styles.locationNewsTile}>
        {header && (
            <header className={styles.tileHeaderWrapper}>
                {icon && <ContentfulImage asset={icon} maxWidth={20} />}
                <p className={styles.tileHeader}>{header}</p>
            </header>
        )}
        {description && <p className={styles.tileText}>{description}</p>}
    </div>
);

export default LocationNewsTile;
