import React from 'react';

import Markup from './markup';
import MainBanner from './mainBanner';
import MainProductBanner from './mainProductBanner';
import SecondaryBanner from './secondaryBanner';
import InfoBlockSection from './infoBlockSection';
import BenefitsOfJoiningSection from './benefitsOfJoiningSection/benefitsOfJoiningSection';
import GiftCardBalance from './giftCardBalance';
import { TopPicksContainer } from './topPicks';
import ErrorBanner from './errorBanner';
import MenuCategorySection from './menuCategory';
import NumberedList from './numberedList/numberedListSection';
import Faq from './faq';
import SingleImageWithOverlay from './singleImageWithOverlay';
import PromoBlock from './promoBlock';
import ImageBlock from './imageBlock';

import { IPageFields } from '../../@generated/@types/contentful';
import EmailSignup from './emailSignup';
import TextBlock from './textBlock';
import { Unpacked } from '../../common/types';
import VideoBlock from './videoBlock';
import PaddingSection from './paddingSection';
import MiniBanners from './miniBanners';
import LocationNewsSection from './locationNewsSection';
import NearbyLocationsSection from './nearbyLocationsSection';
import LocationPromoBanner from './locationPromoBanner';
import PageDescriptionSection from './pageDescriptionSection';
import LocalTapList from './localTapList';
import OpenTableWidget from './openTable/openTable';
import DineTimeWidget from './dineTime/dineTime';
import GoingAtTheBar from './goingAtTheBar';
import Action from './action';
import RecentOrders from './recentOrders';
import { DeliveryPartnersContainer } from './deliveryPartners';
import { ILocationByStateOrProvinceDetailsModel } from '../../@generated/webExpApi';
import TemplateTextBlock from './textBlock/templateTextBlock/templateTextBlock';
import SmallPromotionalBanner from './smallPromotionalBanner';
import { filterSections } from '../../common/helpers/filterSections';
import { useAccount } from '../../redux/hooks';

export type IPageSection = Unpacked<IPageFields['section']>;
export type IPageSections = IPageSection[];

interface PageSectionsProps {
    pageSections: IPageSections;
    className?: string;
    data?: {
        locationDetails?: ILocationByStateOrProvinceDetailsModel;
        description?: string;
        expandedSections?: string[];
        isMenuPage?: boolean;
    };
    classes?: { [key: string]: { [key: string]: string } };
}

export interface ISectionComponentProps {
    entry: IPageSection;
    className?: string;
    data?: {
        locationDetails?: ILocationByStateOrProvinceDetailsModel;
        description?: string;
        expandedSections?: string[];
    };
    classes?: { [key: string]: { [key: string]: string } };
}

export type ISectionComponent = (props: ISectionComponentProps) => JSX.Element;

interface ISectionComponentLookup {
    [id: string]: ISectionComponent;
}

export default function PageSections(props: PageSectionsProps): JSX.Element {
    const { pageSections, className, classes, data } = props;
    const { account } = useAccount();

    const SectionComponentLookup: ISectionComponentLookup = {
        giftCardBalanceSection: GiftCardBalance,
        infoBlockSection: InfoBlockSection,
        mainBanner: MainBanner,
        errorBanner: ErrorBanner,
        mainProductBanner: MainProductBanner,
        markup: Markup,
        menuCategorySection: MenuCategorySection,
        secondaryBanner: SecondaryBanner,
        topPicks: TopPicksContainer,
        numberedListSection: NumberedList,
        frequentlyAskedQuestions: Faq,
        singleImageWithOverlay: SingleImageWithOverlay,
        promoBlockSection: PromoBlock,
        imageBlockSection: ImageBlock,
        emailSignup: EmailSignup,
        textBlockSection: TextBlock,
        templateTextBlockSection: TemplateTextBlock,
        videoBlock: VideoBlock,
        padding: PaddingSection,
        benefitsOfJoining: BenefitsOfJoiningSection,
        miniBanners: MiniBanners,
        locationNewsSection: LocationNewsSection,
        nearbyLocationsSection: NearbyLocationsSection,
        descriptionSection: PageDescriptionSection,
        locationPromoBanner: LocationPromoBanner,
        localTapList: LocalTapList,
        openTable: OpenTableWidget,
        dineTime: DineTimeWidget,
        goingAtTheBar: GoingAtTheBar,
        action: Action,
        recentOrdersSection: RecentOrders,
        deliveryPartners: DeliveryPartnersContainer,
        smallPromotionalBanner: SmallPromotionalBanner,
    };

    const renderedSections =
        pageSections &&
        pageSections
            .filter((pageSection: IPageSection) => {
                return filterSections(!!account, pageSection?.fields?.showOnlyForAuthenticatedUsers);
            })
            .map((pageSection: IPageSection) => {
                const pageSectionsId = pageSection?.sys?.contentType?.sys?.id;
                const SectionComponent = SectionComponentLookup[pageSectionsId];
                if (!SectionComponent) return null;
                return (
                    <SectionComponent
                        key={pageSection.sys.id}
                        entry={pageSection}
                        className={className}
                        classes={classes}
                        data={data}
                    />
                );
            });

    return <div>{renderedSections}</div>;
}
