import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './pageDescriptionSection.module.css';
import { IDescriptionSection } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';
import ContentfulImage from '../../atoms/ContentfulImage';
import TextWithTrademark from '../../atoms/textWithTrademark';
import withSectionLayout from '../withSectionLayout';

export interface IPageDescriptionSectionProps extends ISectionComponentProps {
    entry: IDescriptionSection;
    data?: {
        description?: string;
    };
}

const PageDescriptionSection: FC<IPageDescriptionSectionProps> = (props) => {
    const { description, image, imageLeftAligned } = props.entry.fields;
    const locationDescription = props.data?.description || description;

    const sectionClasses = classNames({
        [styles.imageLeftAligned]: !!imageLeftAligned,
    });

    return (
        <div className={styles.wrapper}>
            <div className={sectionClasses}>
                <div className={styles.content}>
                    <div className={styles.textSection}>
                        <TextWithTrademark
                            tag="span"
                            text={locationDescription}
                            className={classNames('t-paragraph', styles.textBlock)}
                        />
                    </div>
                    <div className={styles.imageSection}>
                        <ContentfulImage asset={image} imageAlt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withSectionLayout(PageDescriptionSection, {
    showBullet: true,
    headerTag: 'h2',
    className: styles.header,
    headerClassName: 't-header-h2',
});
