import { IOrderModel, IOrderProductModel } from '../../@generated/webExpApi/models';
import { useDomainMenuSelectors, useProducts } from '../../redux/hooks/domainMenu';
import { IDomainProductItem } from '../../redux/types';
import { getBagItemSizeLabel } from '../helpers/bagHelper';

export interface IOrderItemProduct extends IOrderProductModel {
    domainProduct: IDomainProductItem;
    sizeLabel: string;
    isCombo?: boolean;
    childDomainProducts?: IDomainProductItem[];
}

interface IOrderItem extends Omit<IOrderModel, 'products'> {
    products: IOrderItemProduct[];
}

export const useOrderItem = (order: IOrderModel): IOrderItem => {
    const domainProducts = useProducts();
    const { selectProductSize } = useDomainMenuSelectors();
    return {
        ...order,
        products: order.products.map((product) => {
            const domainProduct = domainProducts[product.productId];
            const productSize = selectProductSize(domainProduct?.id);
            const productSizeLabel = getBagItemSizeLabel(productSize);
            return {
                ...product,
                domainProduct,
                sizeLabel: productSizeLabel,
                isCombo: !!product.childItems?.length,
                childDomainProducts: product.childItems?.map((item) => domainProducts[item.productId]),
            };
        }),
    };
};
