import React, { FC } from 'react';
import { IPadding } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';
import { getImageUrl } from '../../../common/helpers/contentfulImage';

interface IPaddingSectionProps extends ISectionComponentProps {
    entry: IPadding;
}

const PaddingSection: FC<IPaddingSectionProps> = ({ entry }) => {
    const { height, backgroundColor, backgroundImage } = entry.fields;
    const bgImageUrl = getImageUrl(backgroundImage);

    return (
        <div className="paddingSection">
            <style jsx>{`
                .paddingSection {
                    height: ${height}px;
                    background-color: ${backgroundColor ? `#${backgroundColor}` : 'transparent'};
                    ${bgImageUrl
                        ? `background-image: url("${bgImageUrl}");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;`
                        : ``}
                }
            `}</style>
        </div>
    );
};

export default PaddingSection;
