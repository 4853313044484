import React from 'react';
import classnames from 'classnames';
import { useOrderHistory } from '../../../redux/hooks';
import RecentOrdersHeader from './recentOrdersHeader';
import RecentOrdersCarousel from './recentOrdersCarousel';
import { IRecentOrdersSection } from '../../../@generated/@types/contentful';
import { ORDERS_LIMIT, SHOULD_SORT_ORDERS } from './constants';

import styles from './recentOrders.module.css';
import { isActiveOrder } from '../../../common/helpers/orderHistory/isActiveOrder';

interface IRecentOrdersProps {
    entry: IRecentOrdersSection;
}

const RecentOrders = (props: IRecentOrdersProps): JSX.Element => {
    const { orderHistoryFulfilled } = useOrderHistory();
    const backgroundColor = props.entry.fields.backgroundColor?.fields.hexColor;

    if (!orderHistoryFulfilled?.length) return null;

    const orders = SHOULD_SORT_ORDERS
        ? orderHistoryFulfilled.sort((a, b) => Number(isActiveOrder(b)) - Number(isActiveOrder(a)))
        : orderHistoryFulfilled;

    return (
        <>
            <section className={classnames(styles.container, 'backgroundColor')}>
                <RecentOrdersHeader
                    image={props.entry.fields.image}
                    mainText={props.entry.fields.mainText}
                    secondaryText={props.entry.fields.secondaryText}
                />
                <RecentOrdersCarousel orderHistory={orders.slice(0, ORDERS_LIMIT)} />
            </section>
            <style jsx>{`
                .backgroundColor {
                    background-color: ${backgroundColor ? `#${backgroundColor}` : 'inherit'};
                }
            `}</style>
        </>
    );
};

export default RecentOrders;
