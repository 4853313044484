import React from 'react';
import classnames from 'classnames';

import { IInfoBlockSection } from '../../../@generated/@types/contentful';

import withSectionLayout from '../withSectionLayout';
import { InspireButton } from '../../atoms/button';
import { InspireLink } from '../../atoms/link';
import ContentfulImage from '../../atoms/ContentfulImage';

import { ISectionComponentProps } from '..';

import styles from './infoBlockSection.module.css';
import LegalMessage from '../../atoms/legalMessage';

import { CARD_LINK_WRAPPER_BACKGROUND } from './constants';
import ContentfulBackgroundImageContainer from '../../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';
import { camelCase } from '../../../common/helpers/camelCase';

interface IInfoBlockSectionProps extends ISectionComponentProps {
    entry: IInfoBlockSection;
    classes?: { [key: string]: { [key: string]: string } };
}

function InfoBlockSection(props: IInfoBlockSectionProps): JSX.Element {
    const { cards, name } = props.entry.fields;
    const gtmTextWithTrademark = name && camelCase(name);
    const isSingleCard = cards.length === 1;
    const isDoubleCard = cards.length === 2;
    const isTripleCard = cards.length === 3;
    const isMoreThreeCards = cards.length > 3;
    const classes = props.classes?.infoBlockSection;
    return (
        <div
            className={classnames(styles.sectionFlexContainer, {
                [styles.sectionMoreThree]: isMoreThreeCards,
                [styles.sectionUntitled]: !name,
            })}
        >
            {cards.map((card, i) => {
                const buttonText = card.fields?.buttonText;
                const link = card.fields?.link;

                const headerColor = card.fields?.headerColor?.fields?.hexColor;
                const subheaderColor = card.fields?.subheaderColor?.fields?.hexColor;
                const backgroundColor = card.fields?.backgroundColor?.fields?.hexColor;
                const bgImage = card.fields?.backgroundImage;
                const headerText = card.fields?.headerText;
                const subheaderText = card.fields?.subheaderText;
                const legalMessage = card.fields?.legalMessage;
                const gtmHeaderText =
                    gtmTextWithTrademark &&
                    headerText &&
                    `${gtmTextWithTrademark}${'_'}${headerText.replace(/ /g, '')}`;
                const headerClasses = classnames(styles.cardHeader, 't-header-card-title', `headerConfig${i}`, {
                    truncate: subheaderText,
                    'truncate-at-3': !subheaderText,
                });

                const subheaderClasses = classnames(
                    styles.cardSubheader,
                    't-paragraph',
                    `subheaderConfig${i}`,
                    classes?.cardSubheader
                );

                const CardLinkWrapper = ({ children }) => {
                    const wrapperClasses = classnames(classes?.cardLink);

                    if (link) {
                        return (
                            <ContentfulBackgroundImageContainer
                                asset={bgImage}
                                backgroundColor={backgroundColor ? `#${backgroundColor}` : CARD_LINK_WRAPPER_BACKGROUND}
                                containerClassName={classnames(styles.infoBlockContainer, {
                                    [styles.double]: isDoubleCard,
                                    [styles.triple]: isTripleCard,
                                    [styles.moreThree]: isMoreThreeCards,
                                    [styles.cardWithButton]: buttonText && link,
                                })}
                                backgroundClassName={styles.imageBackgroundContainer}
                            >
                                <InspireLink
                                    className={classnames(wrapperClasses)}
                                    link={link}
                                    {...(gtmHeaderText && { gtmId: gtmHeaderText })}
                                >
                                    {children}
                                </InspireLink>
                            </ContentfulBackgroundImageContainer>
                        );
                    }

                    return (
                        <ContentfulBackgroundImageContainer
                            asset={bgImage}
                            backgroundColor={backgroundColor ? `#${backgroundColor}` : CARD_LINK_WRAPPER_BACKGROUND}
                            containerClassName={classnames(styles.infoBlockContainer, {
                                [styles.double]: isDoubleCard,
                                [styles.triple]: isTripleCard,
                                [styles.moreThree]: isMoreThreeCards,
                            })}
                            backgroundClassName={styles.imageBackgroundContainer}
                        >
                            <div className={wrapperClasses}>{children}</div>
                        </ContentfulBackgroundImageContainer>
                    );
                };

                return (
                    <CardLinkWrapper key={i}>
                        <div
                            className={classnames(styles.card, {
                                [styles.single]: isSingleCard,
                            })}
                        >
                            <ContentfulImage asset={card.fields?.icon} />
                            <div
                                className={classnames(styles.content, {
                                    [styles.contentWithoutButton]: !buttonText || !link,
                                })}
                            >
                                {headerText && (
                                    <h3 className={classnames('t-header-h3', headerClasses)} title={headerText}>
                                        {headerText}
                                    </h3>
                                )}
                                {(subheaderText || legalMessage) && (
                                    <div className={subheaderClasses} title={subheaderText}>
                                        <div>
                                            {subheaderText}
                                            {legalMessage && (
                                                <>
                                                    {' '}
                                                    <LegalMessage
                                                        className={classnames(styles.legalMessage, subheaderClasses)}
                                                        legalMessage={legalMessage}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {buttonText && link && (
                                <div className={styles.buttonSection} title={buttonText}>
                                    <InspireButton
                                        className={classnames('truncate', styles.button)}
                                        text={buttonText}
                                        type="secondary"
                                        isPreviewOnly
                                    />
                                </div>
                            )}
                            <style jsx>{`
                                .headerConfig${i} {
                                    color: ${headerColor ? `#${headerColor}` : 'var(--col--light)'};
                                }
                                .subheaderConfig${i} {
                                    color: ${subheaderColor ? `#${subheaderColor}` : 'var(--col--light)'};
                                }
                            `}</style>
                        </div>
                    </CardLinkWrapper>
                );
            })}
        </div>
    );
}

export default withSectionLayout(InfoBlockSection, {
    showBullet: true,
    headerTag: 'h2',
    className: styles.sectionLayout,
    headerClassName: 't-header-h2',
});
