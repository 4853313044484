import React from 'react';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { InspireButton } from '../../atoms/button';
import { InspireLink } from '../../atoms/link';
import TextWithTrademark from '../../atoms/textWithTrademark';

import { getGtmIdByName } from '../../../lib/gtm';

import styles from './mainDealsBanner.module.css';
import mainBannerStyles from '../mainBanner/mainBanner.module.css';
import { TYPOGRAPHY_CLASS } from '../mainBanner/constants';
import { Extras } from '../../../common/hooks/braze/types';

export interface MainBannerOffer {
    name?: string;
    title?: string;
    description?: string;
    userOfferId?: string;
    imageUrl?: string;
    endDateTime?: string;
    terms?: string;
    extras?: Extras;
}
export interface IMainDealsBannerProps {
    offer?: MainBannerOffer;
    userName?: string;
    isAnyRedeemed?: boolean;
    isRedeemed?: boolean;
    isBrazeBanner?: boolean;
    isCenteredButton?: boolean;
    isPromocodeApplied?: boolean;
}

export default function MainDealsBanner(props: IMainDealsBannerProps): JSX.Element {
    const { userName, offer } = props;

    const gtmId = getGtmIdByName('mainDealsBanner', offer.name);
    const isSmallVariant = useMediaQuery('(max-width: 1024px)');
    const buttonType = isSmallVariant ? 'primary' : 'large';
    const topText = `Hi, ${userName}`;

    return (
        <div data-gtm-id={gtmId} role="banner" className={classNames('mainBannerConf', mainBannerStyles.mainBanner)}>
            <div data-gtm-id={gtmId} className={mainBannerStyles.actionsSection}>
                <div data-gtm-id={gtmId} className={mainBannerStyles.topIconBox}>
                    <TextWithTrademark
                        tag="span"
                        text={topText}
                        data-gtm-id={gtmId}
                        className={classNames('t-subheader-hero', mainBannerStyles.topIconText)}
                    />
                </div>
                <TextWithTrademark
                    tag="h2"
                    text={offer.name}
                    data-gtm-id={gtmId}
                    className={TYPOGRAPHY_CLASS.MAIN_TEXT}
                />
                {offer.description && (
                    <TextWithTrademark
                        tag="p"
                        text={offer.description}
                        data-gtm-id={gtmId}
                        className={mainBannerStyles.bottomText}
                    />
                )}
                <div className={styles.actionsContainer}>
                    <InspireButton
                        text="View Deal"
                        type={buttonType}
                        gtmId={gtmId}
                        link={`/account/deals/deal?id=${offer.userOfferId}`}
                    />
                    <InspireLink
                        text="See all deals"
                        link="/account/deals"
                        type="primary"
                        className={styles.allDealsLink}
                    />
                </div>
            </div>
            <div data-gtm-id={gtmId} className={mainBannerStyles.imageSection}>
                <img src={offer.imageUrl} data-gtm-id={gtmId} className={styles.image} alt="Deal" />
            </div>
        </div>
    );
}
