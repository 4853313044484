import { IAppLink, IExternalLink } from '../../../@generated/@types/contentful';
import { getMobileOperatingSystem, MobileOperatingSystem } from '../../../common/helpers/getMobileOperatingSystem';

export const mobileDeviceSpecificLinkFilter = (link: IExternalLink | IAppLink): boolean => {
    const mobileOperatingSystem = getMobileOperatingSystem();

    const mobileSpecificUrlMap = {
        [MobileOperatingSystem.ANDROID]: 'play.google.com',
        [MobileOperatingSystem.IOS]: 'apps.apple.com',
    };

    return (
        !mobileOperatingSystem ||
        !(link as IAppLink).fields?.image ||
        (link as IAppLink).fields.url?.includes(mobileSpecificUrlMap[mobileOperatingSystem])
    );
};
