import React from 'react';
import Loader from '../Loader';
import getDisplayCalories from './getDisplayCalories';

interface IProductPriceProps {
    calories: number;
    gtmId?: string;
    isLoading?: boolean;
    className?: string;
}

const ProductItemCalories = ({ isLoading, calories, gtmId, className }: IProductPriceProps): JSX.Element => {
    if (isLoading) {
        return (
            <span>
                <Loader size={10} />
            </span>
        );
    }

    return (
        <span className={className} data-gtm-id={gtmId} data-testid={`${calories}-calories`}>
            {getDisplayCalories(calories)}
        </span>
    );
};

export default ProductItemCalories;
