import React, { FC } from 'react';
import classnames from 'classnames';

import { ISectionComponentProps } from '..';
import { ILocationPromoBanner } from '../../../@generated/@types/contentful';
import LegalMessage from '../../atoms/legalMessage';
import ContentfulImage from '../../atoms/ContentfulImage';
import sectionIndentsStyles from '../sectionIndents.module.css';
import styles from './locationPromoBanner.module.css';
import { getImageUrl } from '../../../common/helpers/contentfulImage';

interface ILocationPromoBannerProps extends ISectionComponentProps {
    entry: ILocationPromoBanner;
}

const LocationPromoBanner: FC<ILocationPromoBannerProps> = ({ entry }) => {
    const { fields } = entry;
    const { icon, title, description, legalMessage } = fields;
    const backgroundImageUrl = getImageUrl(fields.backgroundImage);
    const backgroundColor = fields.backgroundColor?.fields.hexColor;
    const titleColor = fields.titleColor?.fields.hexColor;
    const descriptionColor = fields.descriptionColor?.fields.hexColor;

    return (
        <div className={sectionIndentsStyles.wrapper}>
            <div className={styles.wrapper}>
                {icon && <ContentfulImage className={styles.icon} asset={icon} maxWidth={76} />}
                <div className={styles.content}>
                    {title && <div className={classnames(styles.title, 't-header-hero')}>{title}</div>}
                    {(description || legalMessage) && (
                        <div className={classnames(styles.description, 't-paragraph')}>
                            {description}
                            {legalMessage && (
                                <LegalMessage className={classnames(styles.legalMessage)} legalMessage={legalMessage} />
                            )}
                        </div>
                    )}
                </div>
                <style jsx>{`
                    .${styles.wrapper} {
                        background-color: ${backgroundColor ? `#${backgroundColor}` : 'transparent'};
                        ${backgroundImageUrl
                            ? `background-image: url("${backgroundImageUrl}");
                                background-repeat: no-repeat;
                                background-position: center;`
                            : ``}
                    }
                    .${styles.title} {
                        color: #${titleColor};
                    }
                    .${styles.description} {
                        color: #${descriptionColor};
                    }
                `}</style>
            </div>
        </div>
    );
};

export default LocationPromoBanner;
