import React from 'react';
import { Formik, Form } from 'formik';
import className from 'classnames';
import { useDispatch } from 'react-redux';

import { IGiftCardBalanceSection } from '../../@generated/@types/contentful';
import useCardBalance, { TCardBalanceCode } from '../../common/hooks/useCardBalance';
import SectionHeader from '../atoms/sectionHeader';
import FormikInput from '../organisms/formikInput';
import { InspireButton } from '../atoms/button';
import { validateGiftCardNumber, validateGiftCardPin } from '../../common/helpers/complexValidateHelper';
import { GTM_GIFT_CARD_BALANCE_FORM_SUBMIT_SUCCESS } from '../../common/services/gtmService/constants';
import BrandLoader from '../atoms/BrandLoader';
import { formatPrice } from '../../lib/domainProduct';

import sectionIndentsStyles from './sectionIndents.module.css';
import styles from './giftCardBalance.module.css';

interface ICardBalanceSectionProps {
    entry: IGiftCardBalanceSection;
}

export interface IGiftCardForm {
    cardNumber: string;
    cardPin: string;
}

const initialValues: IGiftCardForm = {
    cardNumber: '',
    cardPin: '',
};

const CARD_NUMBER_PREFIX = '308650';

const commonDescription = 'Please try entering your gift card number and PIN again.';
const commonSubhead = 'We’re sorry';

const resultMessages = {
    [TCardBalanceCode.SUCCESS]: {
        subhead: 'Gift Card Balance',
        description: 'You can pay with this gift card in any Arby’s restaurant located in the U.S.',
    },
    [TCardBalanceCode.INVALID]: {
        subhead: commonSubhead,
        description: commonDescription,
    },
    [TCardBalanceCode.DECLINED]: {
        subhead: commonSubhead,
        description: commonDescription,
    },
    [TCardBalanceCode.OTHER]: {
        subhead: 'Operation Declined',
        description: commonDescription,
    },
};

const GiftCardBalance = (props: ICardBalanceSectionProps): JSX.Element => {
    const { balance, loading, error, code, getBalance } = useCardBalance();
    const { header, ctaText } = props.entry.fields;
    const dispatch = useDispatch();

    const handleSubmit = async (values: IGiftCardForm) => {
        const { cardNumber, cardPin } = values;

        getBalance(cardNumber, cardPin).then(() => {
            dispatch({
                type: GTM_GIFT_CARD_BALANCE_FORM_SUBMIT_SUCCESS,
            });
        });
    };

    const formClasses = className(styles.formContainer, {
        [styles.formContainerHide]: loading || balance || error,
    });

    const resultClasses = className(styles.resultContainer, {
        [styles.resultContainerHide]: !(loading || balance || error),
    });

    const { subhead, description } = resultMessages[code] || resultMessages[TCardBalanceCode.OTHER];

    const testGiftCardNumber = validateGiftCardNumber();

    const testGiftCardPin = validateGiftCardPin(false);
    const testGiftCardPinRequired = validateGiftCardPin(true);

    const validate = ({ cardNumber, cardPin }) => {
        const errors: {
            cardNumber?: string | void | Promise<string | void>;
            cardPin?: string | void | Promise<string | void>;
        } = {};

        errors.cardNumber = testGiftCardNumber(cardNumber);

        if (cardNumber.startsWith(CARD_NUMBER_PREFIX)) {
            errors.cardPin = testGiftCardPinRequired(cardPin);
        } else {
            errors.cardPin = testGiftCardPin(cardPin);
        }

        const filteredErrors = Object.keys(errors).reduce((result, key) => {
            if (errors[key]) {
                return { ...result, [key]: errors[key] };
            }

            return result;
        }, {});

        return filteredErrors;
    };

    return (
        <section className={sectionIndentsStyles.wrapper} aria-label="Gift Card Balance Check">
            <SectionHeader text={header} />
            <div className={styles.contentWrapper}>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validateOnMount
                    validateOnChange
                    validate={validate}
                >
                    {({ isValid, isSubmitting, isValidating }) => {
                        return (
                            <Form className={formClasses} aria-label="form">
                                <FormikInput
                                    className={styles.inputContainer}
                                    description="19-Digits (No Spaces)"
                                    inputClassName={styles.input}
                                    label="CARD NUMBER"
                                    labelClassName={styles.inputLabel}
                                    maxLength={19}
                                    name="cardNumber"
                                    placeholder="Enter Card Number"
                                    required
                                    showErrorImmediately
                                    type="text"
                                />
                                <FormikInput
                                    className={styles.inputContainer}
                                    description="4-Digits (Enter if Present)"
                                    inputClassName={styles.input}
                                    label="PIN"
                                    labelClassName={styles.inputLabel}
                                    maxLength={12}
                                    name="cardPin"
                                    placeholder="Enter PIN"
                                    required
                                    showErrorImmediately
                                    type="password"
                                />
                                <InspireButton
                                    className={className(styles.button, 'truncate')}
                                    disabled={loading || !isValid || isSubmitting || isValidating}
                                    text={ctaText}
                                    submit
                                    type="secondary"
                                />
                            </Form>
                        );
                    }}
                </Formik>
                <div className={resultClasses}>
                    {loading && <BrandLoader />}
                    {!loading && (balance || error) && (
                        <div className={styles.result}>
                            <div className="t-subheader">{subhead}</div>
                            <div className={className(styles.head, 't-header-h1')}>{error || formatPrice(balance)}</div>
                            <div className={styles.description}>{description}</div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default GiftCardBalance;
