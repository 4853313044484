import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styles from './index.module.css';

interface IHScroller {
    className?: string;
    listClassName?: string;
    buttonClassName?: string;
    prevButtonClassName?: string;
    nextButtonClassName?: string;
    iconClassName?: string;
    children?: JSX.Element[] | string[];
    scrollStep?: number;
    itemType?: string;
    disabled?: boolean;
}

const HScroller = (props: IHScroller): JSX.Element => {
    const ref = useRef<HTMLDivElement>();
    const { itemType, disabled } = props;
    const buttonType = itemType || 'product';
    const [clientWidth, setClientWidth] = useState(null);
    const [scrollWidth, setScrollWidth] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);
    const [scrollStep, setScrollStep] = useState(null);

    const [isNextAvailable, setIsNextAvailable] = useState(false);
    const [isPrevAvailable, setIsPrevAvailable] = useState(false);

    const current = ref.current;

    useEffect(() => {
        const handler = () => {
            setClientWidth(current?.clientWidth);
            setScrollWidth(current?.scrollWidth);
            setScrollLeft(current?.scrollLeft);
        };

        current?.addEventListener('scroll', handler);
        window.addEventListener('resize', handler);

        handler();

        return () => {
            current?.removeEventListener('scroll', handler);
            window.removeEventListener('resize', handler);
        };
    }, [current, props.children]);

    useEffect(() => {
        setIsNextAvailable(scrollWidth - clientWidth > Math.ceil(scrollLeft));
        setIsPrevAvailable(scrollLeft > 0);
        setScrollStep(Math.ceil((scrollWidth - clientWidth) / Math.round(scrollWidth / clientWidth)));
    }, [clientWidth, scrollWidth, scrollLeft]);

    const toScroll = props.scrollStep || scrollStep;
    const onClickNext = () => ref?.current?.scrollTo({ left: scrollLeft + toScroll, behavior: 'smooth' });
    const onClickPrev = () => ref?.current?.scrollTo({ left: scrollLeft - toScroll, behavior: 'smooth' });

    return (
        <div className={classnames([styles.scrollerContainer, props.className])}>
            {!disabled && (
                <IconButton
                    disableRipple
                    className={classnames(
                        [styles.prevButton, styles.button, props.buttonClassName, props.prevButtonClassName],
                        {
                            [styles.buttonVisible]: isPrevAvailable,
                        }
                    )}
                    name={`Previous ${buttonType}`}
                    aria-label={`Previous ${buttonType}`}
                    onClick={onClickPrev}
                >
                    <ChevronLeftIcon className={classnames(styles.prevIcon, props.iconClassName)} />
                </IconButton>
            )}
            <div className={styles.wrapper} ref={ref}>
                <div className={classnames(styles.scrollerList, props.listClassName)}>{props.children}</div>
            </div>
            {!disabled && (
                <IconButton
                    disableRipple
                    className={classnames(
                        [styles.nextButton, styles.button, props.buttonClassName, props.nextButtonClassName],
                        {
                            [styles.buttonVisible]: isNextAvailable,
                        }
                    )}
                    name={`Next ${buttonType}`}
                    aria-label={`Next ${buttonType}`}
                    onClick={onClickNext}
                >
                    <ChevronRightIcon className={classnames(styles.prevIcon, props.iconClassName)} />
                </IconButton>
            )}
            {disabled && <div className={styles.overlay}></div>}
        </div>
    );
};

export default HScroller;
