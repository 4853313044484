export const TYPOGRAPHY_CLASS = {
    TOP_TEXT: 't-subheader-hero',
    TOP_TEXT_H2: 't-header-h2',
    TOP_TEXT_MOBILE: 't-subheader-hero',
    MAIN_TEXT: 't-header-hero',
    MAIN_TEXT_H1_SIZE: 't-header-h1',
    MAIN_TEXT_H2_SIZE: 't-header-h2',
};

export const IMAGES_CLASS = {
    maxWidth: 650,
    objectFit: 'contain',
};

export const CHANGE_BUTTON_TYPE = true;
