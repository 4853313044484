import React, { useCallback, useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { ISignUpForm } from './types';
import { PickupAddress } from '../../../redux/orderLocation';
import { locationSearch, getCoordinates } from '../../../common/services/locationService';
import { validateZipCode } from '../../../common/helpers/complexValidateHelper';

import Dropdown from '../../atoms/dropdown';
import FormikInput from '../../organisms/formikInput';
import { LocationWithDetailsModel } from '../../../common/services/locationService/types';

import styles from './index.module.css';

interface ILocationField {
    inputClassName?: string;
    errorClassName?: string;
    labelClassName?: string;
}

const LocationField = ({ inputClassName }: ILocationField): JSX.Element => {
    const testLocation = useCallback(
        (value: LocationWithDetailsModel) => (!value ? 'Preffered Location' : undefined),
        []
    );

    const {
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        registerField,
        unregisterField,
        validateForm,
    } = useFormikContext<ISignUpForm>();

    const { location: locationValue, zipCode: zipCodeValue } = values;

    const [prevZipCodeValue, setPrevZipCodeValue] = useState<string>(zipCodeValue);
    const [isLocationsDisabled, setIsLocationsDisabled] = useState<boolean>(false);

    const [locationsList, setLocationsList] = useState<PickupAddress[]>([]);
    const [loading, setLoading] = useState(false);

    const testZipCode = useCallback(
        (value) => {
            const testZipCode = validateZipCode('Zip Code');
            const testLocationsList = (zipcode) =>
                zipcode === prevZipCodeValue &&
                !loading &&
                !locationsList.length &&
                'Sorry, the needed location is not found';

            return testZipCode(value) || testLocationsList(value);
        },
        [prevZipCodeValue, loading, locationsList.length]
    );

    const onLocationSearch = async (zipcode?: string) => {
        if (zipcode !== '') {
            let data: PickupAddress[] = [];

            setLoading(true);
            setLocationsList([]);

            try {
                const coordinates = await getCoordinates(zipcode);
                const result = await locationSearch(coordinates);

                data = result.locations;
            } catch (e) {
                console.log(e);
            }

            setLocationsList(data);
            if (!locationValue && data[0]?.id) {
                setFieldValue('location', data[0]);
            }

            setLoading(false);
        }
    };

    const handleSearch = () => {
        if (!!prevZipCodeValue && prevZipCodeValue !== zipCodeValue) {
            setFieldValue('location', undefined);
            setFieldTouched('location', false);
        }

        if (prevZipCodeValue !== zipCodeValue) {
            onLocationSearch(zipCodeValue);
        }
    };

    const handleZipCodeBlur = () => {
        setFieldTouched('zipCode');
        validateForm();

        if (!errors.zipCode) {
            setIsLocationsDisabled(false);
            setPrevZipCodeValue(zipCodeValue);
            handleSearch();
        }
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.target.blur();
        }
    };

    const handleCloseDropdown = () => {
        setFieldTouched('location');
    };

    const handleChangeZipCode = (e) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setFieldValue('zipCode', e.target.value);
            setFieldValue('location', undefined);
            setFieldTouched('location', false);
            setIsLocationsDisabled(true);
        }
    };

    useEffect(() => {
        if (zipCodeValue) {
            onLocationSearch(zipCodeValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerField('location', { validate: testLocation });
        return () => {
            unregisterField('location');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateForm();
    }, [locationValue?.id, validateForm, loading]);

    const dropDownTarget = !locationValue ? <p>Select Your Preferred Location</p> : locationValue.displayName;

    const showLocationError = touched.location && errors.location;

    return (
        <>
            <FormikInput
                name="zipCode"
                label="Zip Code"
                placeholder="Enter Zip Code"
                className={styles.inputContainer}
                inputClassName={inputClassName}
                errorClassName={styles.inputError}
                labelClassName={styles.inputLabel}
                onBlur={handleZipCodeBlur}
                onKeyPress={onKeyPress}
                onChange={handleChangeZipCode}
                max={99999}
                validate={testZipCode}
                required
            />
            <div className={styles.dropdown}>
                <Dropdown
                    label="YOUR PREFERRED ARBY’S® LOCATION *"
                    listClassName={styles.listClassName}
                    buttonClassName={classnames({ [styles.dropdownError]: showLocationError })}
                    target={dropDownTarget}
                    disabled={isLocationsDisabled || locationsList.length === 0 || !zipCodeValue || loading}
                    active={!!locationValue}
                    onClose={handleCloseDropdown}
                >
                    {locationsList?.map((location) => {
                        const isSelected = location.id === locationValue?.id;
                        return (
                            <MenuItem
                                key={location.id}
                                selected={isSelected}
                                onClick={() => setFieldValue('location', location)}
                                disableRipple
                                className={classnames(styles.dropdownItem, {
                                    [styles.selectedDropdownItem]: !!isSelected,
                                })}
                            >
                                <span className="truncate">{location.displayName}</span>
                            </MenuItem>
                        );
                    })}
                </Dropdown>
                {showLocationError && <div className={styles.inputError}>{errors.location}</div>}
            </div>
        </>
    );
};

export default LocationField;
