import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import classnames from 'classnames';

import LocationField from './locationField';
import Message from './message';
import { DUPLICATE_EMAIL_MESSAGE_TITLE, DUPLICATE_EMAIL_MESSAGE_TEXT } from './constants';
import InfoBanner from '../../atoms/infoBanner';
import { InspireButton } from '../../atoms/button';
import FormikInput from '../../organisms/formikInput';
import { validateAlpha, validateEmail, validateDateOfBirth } from '../../../common/helpers/complexValidateHelper';
import { useOrderLocation } from '../../../redux/hooks';
import { IEmailSignup } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '../../sections';

import styles from './index.module.css';
import useEmailSignup from './useEmailSignup';
import { ISignUpForm, EmailSignupState } from './types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import useRichTextOptions from '../../../common/hooks/useRichTextOptions';
import { getGtmIdByName } from '../../../lib/gtm';
import { autoCorrectedDatePipe } from '../../../common/helpers/autoCorrectedDatePipe';

const initialValues: ISignUpForm = {
    firstName: '',
    lastName: '',
    email: '',
    zipCode: '',
    dateOfBirth: '',
};

interface IEmailSignupSection extends ISectionComponentProps {
    entry: IEmailSignup;
}

const EmailSignup = (props: IEmailSignupSection): JSX.Element => {
    const {
        formFooterRichText,
        formLinkText,
        formTitle,
        legalText,
        header,
        successMessageIcon,
        successMessageHeaderText,
        successMessageText,
    } = props.entry.fields || {};
    const { title, header: headerText, description, headerTextColor, headerBackgroundColor } = header?.fields || {};

    const testFirstName = useMemo(() => validateAlpha('First Name', 256), []);
    const testLastName = useMemo(() => validateAlpha('Last Name', 256), []);
    const testEmail = useMemo(() => validateEmail('Email'), []);
    const testDateOfBirth = useMemo(() => validateDateOfBirth('Date Of Birth'), []);

    const { currentLocation } = useOrderLocation();
    const richTextOptions = useRichTextOptions();

    const initialZipCode = currentLocation?.contactDetails.address.postalCode;

    const initial = {
        ...initialValues,
        zipCode: initialZipCode ? initialZipCode.slice(0, 5) : '',
        location: currentLocation,
    };

    const { signupState, onFormSubmit } = useEmailSignup();

    return (
        <section className={styles.container}>
            <InfoBanner
                title={title}
                mainText={headerText}
                description={description}
                backgroundColor={headerBackgroundColor}
                textColor={headerTextColor}
                className={styles.banner}
            />
            <div className={styles.wrapper}>
                <div className={classnames(styles.container, 'backgroundHalfForm')}>
                    {signupState === EmailSignupState.SIGNUP_FORM_SHOWED && (
                        <Formik onSubmit={onFormSubmit} initialValues={initial} validateOnMount>
                            {({ isValid, isSubmitting, isValidating }) => (
                                <Form className={styles.formContainer}>
                                    <h3 className={classnames('t-header-h3', styles.title)}>{formTitle}</h3>
                                    <FormikInput
                                        name="firstName"
                                        label="First Name"
                                        type="text"
                                        placeholder="Enter First Name"
                                        className={styles.inputContainer}
                                        inputClassName={styles.input}
                                        errorClassName={styles.inputError}
                                        labelClassName={styles.inputLabel}
                                        maxLength={256}
                                        validate={testFirstName}
                                        required
                                    />
                                    <FormikInput
                                        name="lastName"
                                        label="Last Name"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        className={styles.inputContainer}
                                        inputClassName={styles.input}
                                        errorClassName={styles.inputError}
                                        labelClassName={styles.inputLabel}
                                        maxLength={256}
                                        validate={testLastName}
                                        required
                                    />
                                    <FormikInput
                                        name="email"
                                        label="Email"
                                        type="email"
                                        placeholder="Enter Email"
                                        className={styles.inputContainer}
                                        inputClassName={styles.input}
                                        errorClassName={styles.inputError}
                                        labelClassName={styles.inputLabel}
                                        validate={testEmail}
                                        maxLength={256}
                                        required
                                    />
                                    <LocationField inputClassName={styles.input} />
                                    <FormikInput
                                        name="dateOfBirth"
                                        label="Date Of Birth (optional)"
                                        type="text"
                                        placeholder="MM/DD"
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                                        pipe={autoCorrectedDatePipe}
                                        className={styles.inputContainer}
                                        inputClassName={styles.input}
                                        errorClassName={styles.inputError}
                                        labelClassName={styles.inputLabel}
                                        validate={testDateOfBirth}
                                    />
                                    <span
                                        className={classnames('t-paragraph-hint', styles.footerText)}
                                        dangerouslySetInnerHTML={{
                                            __html: documentToHtmlString(formFooterRichText, richTextOptions),
                                        }}
                                    />
                                    <div className={styles.buttonWrapper}>
                                        <InspireButton
                                            className="truncate"
                                            disabled={!isValid || isSubmitting || isValidating}
                                            text={formLinkText || 'Sign up'}
                                            submit
                                            gtmId={getGtmIdByName('emailSignup', formLinkText)}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {signupState === EmailSignupState.SUCCESSFULLY_SUBMITTED && (
                        <Message
                            icon={successMessageIcon}
                            headerText={successMessageHeaderText}
                            messageText={successMessageText}
                        />
                    )}
                    {signupState === EmailSignupState.DUPLICATE_EMAIL_ERROR && (
                        <Message
                            icon={successMessageIcon}
                            headerText={DUPLICATE_EMAIL_MESSAGE_TITLE}
                            messageText={DUPLICATE_EMAIL_MESSAGE_TEXT}
                        />
                    )}
                </div>
                <style jsx>{`
                    .backgroundHalfForm {
                        background: linear-gradient(
                            to bottom,
                            ${headerBackgroundColor || 'var(--col--primary1)'} 50%,
                            transparent 50%
                        );
                    }
                `}</style>
                <span className={classnames('t-paragraph-hint', styles.legalText)}>{legalText}</span>
            </div>
        </section>
    );
};

export default EmailSignup;
