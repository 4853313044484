import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { useFeatureFlags } from '../../redux/hooks/useFeatureFlags';
import { authorizationHeaderBuilder } from '../helpers/accountHelper';
import LoyaltyService from '../services/customerService/loyalty';

const useLoyaltyService = (): LoyaltyService => {
    const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
    const { featureFlags } = useFeatureFlags();
    const [idToken, setIdToken] = useState<string>('');
    useEffect(() => {
        if (featureFlags.isEpsilonRewardsOn) {
            getIdTokenClaims().then((res) => {
                setIdToken(res?.__raw);
            });
        }
    }, [isAuthenticated, user, featureFlags.isEpsilonRewardsOn, getIdTokenClaims]);

    const loyaltyService = useMemo(() => {
        return new LoyaltyService(authorizationHeaderBuilder(idToken));
    }, [idToken]);

    return idToken ? loyaltyService : null;
};

export default useLoyaltyService;
