import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';

import { ITextBlockSectionAccordionItem } from '../../../@generated/@types/contentful';
import Icon from '../../atoms/BrandIcon';
import RichText from '../../atoms/richText';
import styles from './accordionItem.module.css';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import useRichTextOptions from '../../../common/hooks/useRichTextOptions';
import { useContentfulTemplatedText } from '../../../common/hooks/useContentfulTemplatedText';

interface IAccordionItemProps {
    entry: ITextBlockSectionAccordionItem;
    contentClassName?: string;
    hasTemplate?: boolean;
}

const AccordionItem: FC<IAccordionItemProps> = ({
    entry: {
        fields: { text, title, content },
    },
    contentClassName,
    hasTemplate,
}) => {
    const [collapsed, setCollapsed] = useState(false);
    const richTextOptions = useRichTextOptions();
    const getContentfulTemplatedText = useContentfulTemplatedText();

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.collapsed]: collapsed,
            })}
        >
            <div className={styles.header} onClick={() => setCollapsed(!collapsed)}>
                <div className={styles.wrapperHeadings}>
                    <span
                        className={classNames('t-subheader', 'truncate', {
                            [styles.collapsed]: collapsed,
                        })}
                    >
                        {title}
                    </span>
                    {text && <span className={classNames('t-paragraph-hint', 'truncate', styles.text)}>{text}</span>}
                </div>
                <Icon className={styles.icon} icon={collapsed ? 'direction-up' : 'direction-down'} />
            </div>
            <Collapse in={collapsed}>
                {hasTemplate ? (
                    <div
                        className={classNames(styles.content, contentClassName)}
                        dangerouslySetInnerHTML={{
                            __html: getContentfulTemplatedText(documentToHtmlString(content, richTextOptions)),
                        }}
                    />
                ) : (
                    <RichText className={classNames(styles.content, contentClassName)} text={content} />
                )}
            </Collapse>
        </div>
    );
};

export default AccordionItem;
