import React, { FC, useState } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import {
    GTM_RECENT_ORDERS_HAVE_QUESTION,
    GTM_RECENT_ORDERS_REORDER,
} from '../../../../common/services/gtmService/constants';
import { IOrderModel } from '../../../../@generated/webExpApi';
import useAddToBagFromOrderHistory from '../../../../common/hooks/useAddToBagFromOrderHistory';
import HaveAQuestionModal from '../haveAQuestionModal';
import RecentOrderItem from './recentOrderItem';
import { InspireLinkButton } from '../../../atoms/link/linkButton';
import { useOrderLocation } from '../../../../redux/hooks';
import { isActiveOrder } from '../../../../common/helpers/orderHistory/isActiveOrder';
import { getProductsAvailability } from '../../../../common/helpers/orderHistory/getProductsAvailability';
import { useOrderItem } from '../../../../common/hooks/useOrderItem';
import { CONSTANTS } from '../../../../constants/en/commons';
import { PAGE_TITLE } from './constants';

interface IRecentOrderItemProps {
    order: IOrderModel;
}

enum CTA_TYPES {
    HAVE_A_QUESTION = 'HAVE_A_QUESTION',
    REORDER = 'REORDER',
}

const CTA_TEXT_MAP = {
    [CTA_TYPES.HAVE_A_QUESTION]: 'Have a question?',
    [CTA_TYPES.REORDER]: 'Reorder',
};

const RecentOrderItemContainer: FC<IRecentOrderItemProps> = ({ order }) => {
    const dispatch = useAppDispatch();

    const { addFromOrderHistory } = useAddToBagFromOrderHistory(order);
    const { isCurrentLocationOAAvailable } = useOrderLocation();

    const isOrderActive = isActiveOrder(order);

    const ctaType = isOrderActive ? CTA_TYPES.HAVE_A_QUESTION : CTA_TYPES.REORDER;

    const { products } = useOrderItem(order);

    const { areSomeProductsUnavailable } = getProductsAvailability(products, true);
    const unavailableItemsText =
        isCurrentLocationOAAvailable && areSomeProductsUnavailable ? CONSTANTS.SOME_ITEMS_UNAVAILABLE : null;

    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const handleCloseModal = () => {
        setModalOpened(false);
    };

    const handleClickLinkButton = () => {
        if (ctaType === CTA_TYPES.HAVE_A_QUESTION) {
            setModalOpened(true);
            dispatch({
                type: GTM_RECENT_ORDERS_HAVE_QUESTION,
                payload: { ctaName: 'Have a question?', storeId: order.fulfillment.location.id },
            });
        } else {
            addFromOrderHistory();
            dispatch({ type: GTM_RECENT_ORDERS_REORDER, payload: { orderId: order.id, pageTitle: PAGE_TITLE } });
        }
    };

    const renderActions = () =>
        !isCurrentLocationOAAvailable && !isOrderActive ? null : (
            <InspireLinkButton linkType="secondary" onClick={handleClickLinkButton}>
                {CTA_TEXT_MAP[ctaType]}
            </InspireLinkButton>
        );

    return (
        <>
            <RecentOrderItem
                renderActions={renderActions}
                isOrderActive={isOrderActive}
                order={order}
                products={products}
                unavailableItemsText={unavailableItemsText}
            />
            <HaveAQuestionModal
                location={order.fulfillment.location}
                isOpen={modalOpened}
                onModalClose={handleCloseModal}
            />
        </>
    );
};

export default RecentOrderItemContainer;
