import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';
import HScroller from '../../atoms/HScroller';
import EventCard from '../../atoms/eventCard';
import { ISectionComponentProps } from '..';
import { IGoingAtTheBar } from '../../../@generated/@types/contentful';

export interface IGoingAtTheBarProps extends ISectionComponentProps {
    entry: IGoingAtTheBar;
}

const GoingAtTheBar = (props: IGoingAtTheBarProps): JSX.Element => {
    const {
        entry: { fields },
    } = props;

    if (!fields) return null;

    const { title, subtitle, backgroundColor, eventCards } = fields;

    const bgColor = backgroundColor?.fields?.hexColor;

    return (
        <>
            <div className={classNames(styles.wrapperBackground, 'backgroundCol')}>
                <div className={classNames('m-block-treatment', 'm-block-treatment-md', styles.textBlock)}>
                    {subtitle && (
                        <p className={classNames('m-block-treatment-subtitle', styles.subTitle)}>{subtitle}</p>
                    )}
                    <p className={classNames('m-block-treatment-title', styles.title)}>{title}</p>
                </div>
                <div className={styles.eventCards}>
                    <HScroller
                        listClassName={styles.scrollerList}
                        className={styles.scroller}
                        scrollStep={319}
                        prevButtonClassName={styles.prevButton}
                        nextButtonClassName={styles.nextButton}
                    >
                        {eventCards.map((card) => {
                            const {
                                backgroundColor: backgroundCardColor,
                                eventDescription,
                                eventIcon,
                                eventLinkHref,
                                eventLinkText,
                                eventName,
                                legalMessage,
                            } = card.fields;
                            return (
                                <EventCard
                                    key={eventName}
                                    eventIcon={eventIcon}
                                    eventName={eventName}
                                    eventDescription={eventDescription}
                                    eventLinkHref={eventLinkHref}
                                    eventLinkText={eventLinkText}
                                    backgroundColor={backgroundCardColor}
                                    legalMessage={legalMessage}
                                />
                            );
                        })}
                    </HScroller>
                </div>
            </div>
            <style jsx>{`
                .backgroundCol {
                    background-color: ${bgColor ? `#${bgColor}` : 'var(--col--secondary2)'};
                }
            `}</style>
        </>
    );
};

export default GoingAtTheBar;
