import classNames from 'classnames';
import React from 'react';
import { ISmallPromotionalBanner } from '../../../@generated/@types/contentful';
import ContentfulImage from '../../atoms/ContentfulImage';
import { Divider } from '../../atoms/divider';
import LegalMessage from '../../atoms/legalMessage';
import styles from './smallPromotionalBanner.module.css';

interface ISmallPromotionalBannerProps {
    entry: ISmallPromotionalBanner;
}

function SmallPromotionalBanner({ entry: { fields } }: ISmallPromotionalBannerProps) {
    const { title, legalMessage, description, image } = fields;

    return (
        <section className={styles.container}>
            <ContentfulImage asset={image} maxWidth={44} />
            <Divider className={styles.divider} />
            <div className={styles.textContent}>
                <p className="t-paragraph-small-strong">{title}</p>
                <p className={classNames('t-paragraph-hint', styles.description)}>
                    {description}
                    {legalMessage && <LegalMessage legalMessage={legalMessage} className={styles.legalMessage} />}
                </p>
            </div>
        </section>
    );
}

export default SmallPromotionalBanner;
