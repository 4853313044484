import React from 'react';
import classnames from 'classnames';

import styles from './withSectionLayout.module.css';
import { ISectionComponent, ISectionComponentProps } from '.';
import SectionHeader, { ISectionHeaderProps } from '../atoms/sectionHeader';
import ContentfulBackgroundImageContainer from '../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';
import { camelCase } from '../../common/helpers/camelCase';

interface IWithSectionLayoutParams {
    showBullet?: boolean;
    headerTag?: ISectionHeaderProps['tag'];
    className?: string;
    classes?: { [key: string]: { [key: string]: string } };
    headerClassName?: string;
}

export default function withSectionLayout(
    Component: ISectionComponent | React.ComponentClass<ISectionComponentProps>,
    { showBullet, headerTag, className, headerClassName }: IWithSectionLayoutParams = {}
): ISectionComponent {
    const Wrapper = (props: ISectionComponentProps) => {
        // @ts-ignore
        const { name, backgroundPosition, backgroundColor, backgroundText, backgroundBottomText } = props.entry.fields;
        const gtmTextWithTrademark = name && camelCase(name);
        const gtmMoreToLoveSection = gtmTextWithTrademark === 'moreToLove' && `${gtmTextWithTrademark + '_Block'}`;
        // @ts-ignore
        const { backgroundImage, fullSizeBackgroundImage } = props.entry.fields;
        const classes = props.classes?.sectionLayout;
        const doesBackgroundImageExists = !!backgroundImage?.fields?.file?.url;
        const bgHexColor = backgroundColor?.fields.hexColor;
        const isBackgroundImagePartial = !fullSizeBackgroundImage && doesBackgroundImageExists;

        const backgroundTileClasses = classnames(styles.backgroundTile, {
            [styles.top]: backgroundPosition === 'front',
            [styles.bottom]: backgroundPosition === 'back',
            [styles.hidden]: !backgroundPosition || backgroundPosition === 'hidden',
            [styles.backgroundTileColor]: styles.backgroundTileColor && !backgroundColor,
        });

        return (
            <ContentfulBackgroundImageContainer
                asset={backgroundImage}
                backgroundColor={
                    fullSizeBackgroundImage && !backgroundImage && bgHexColor ? `#${bgHexColor}` : 'transparent'
                }
            >
                <div
                    className={classnames(styles.sectionWrapper, {
                        [className]: !!className,
                        [styles.sectionWrapperBackground]: fullSizeBackgroundImage,
                        [`${styles.partialBackground}`]: isBackgroundImagePartial,
                    })}
                    {...(gtmMoreToLoveSection && { 'data-gtm-id': gtmMoreToLoveSection })}
                >
                    <div
                        className={classnames(
                            styles.wrapperComponents,
                            {
                                [styles.withBackgroundText]: !!backgroundText,
                            },
                            classes?.wrapper
                        )}
                    >
                        <SectionHeader
                            showBullet={!!showBullet}
                            text={name}
                            tag={headerTag}
                            className={classnames({
                                'visually-hidden': backgroundPosition === 'front',
                                [styles.headerLight]: fullSizeBackgroundImage && doesBackgroundImageExists,
                            })}
                            textClassName={classnames(
                                {
                                    ['t-header-h2']: isBackgroundImagePartial,
                                    [styles.sectionHeaderText]: isBackgroundImagePartial,
                                },
                                classes?.mainTitle,
                                headerClassName
                            )}
                        />
                        <div className={classnames('truncate', styles.backgroundText)}>{backgroundText}</div>
                    </div>
                    <div
                        className={classnames(styles.sectionFlexContainer, classes?.mainContent)}
                        {...(gtmTextWithTrademark && { 'data-gtm-id': gtmTextWithTrademark })}
                    >
                        <Component {...props} />
                    </div>
                    {backgroundBottomText && (isBackgroundImagePartial || !fullSizeBackgroundImage) && (
                        <ContentfulBackgroundImageContainer
                            asset={backgroundImage}
                            containerClassName={backgroundTileClasses}
                            backgroundColor={backgroundColor ? `#${backgroundColor.fields.hexColor}` : ''}
                        />
                    )}
                </div>
            </ContentfulBackgroundImageContainer>
        );
    };

    Wrapper.displayName = `withSectionLayout(${Component?.name || 'Unknown'})`;

    return Wrapper;
}
