import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { signUp } from '../../../common/services/customerService';
import { GTM_EMAIL_SIGN_UP } from '../../../common/services/gtmService/constants';
import { useNotifications } from '../../../redux/hooks';
import { ISignUpForm, EmailSignupState } from './types';
import { DEFAULT_ERROR_MESSAGE_TEXT } from './constants';
import isEmailAlreadyRegistered from './isEmailAlreadyRegistered';

interface IEmailSignupHook {
    signupState: EmailSignupState;
    onFormSubmit: (values: ISignUpForm) => Promise<void>;
}

const useEmailSignup = (): IEmailSignupHook => {
    const [signupState, setSignupState] = useState(EmailSignupState.SIGNUP_FORM_SHOWED);
    const dispatch = useDispatch();
    const {
        actions: { enqueueError },
    } = useNotifications();

    const onFormSubmit = async (values: ISignUpForm) => {
        try {
            await signUp({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                postalCode: values.zipCode,
                storeId: values.location.id,
                storeName: values.location.displayName,
                birthDate: values.dateOfBirth?.replace('/', '-'),
            }).then(() => dispatch({ type: GTM_EMAIL_SIGN_UP }));
            setSignupState(EmailSignupState.SUCCESSFULLY_SUBMITTED);
            window.scrollTo(0, 0);
        } catch (e) {
            if (isEmailAlreadyRegistered(e)) {
                setSignupState(EmailSignupState.DUPLICATE_EMAIL_ERROR);
                window.scrollTo(0, 0);
            } else {
                enqueueError({ message: DEFAULT_ERROR_MESSAGE_TEXT });
            }
        }
    };

    return {
        signupState,
        onFormSubmit,
    };
};

export default useEmailSignup;
