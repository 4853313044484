import React, { FC } from 'react';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { IMainBanner, IMainBannerFields } from '../../../@generated/@types/contentful';

import { InspireButton, InspireButtonType } from '../../atoms/button';
import { InspireLink } from '../../atoms/link';
import ContentfulImage from '../../atoms/ContentfulImage';
import { getGtmIdByName } from '../../../lib/gtm';

import { ISectionComponentProps } from '..';

import styles from './mainBanner.module.css';
import { Asset } from 'contentful';
import TextWithTrademark from '../../atoms/textWithTrademark';
import VideoBlock from '../videoBlock';
import RichText from '../../atoms/richText';
import LegalMessage from '../../atoms/legalMessage';
import ViewDetails from '../../molecules/viewDetails';

import { UMLAUT_SYMBOLS_REGEX } from '../../../common/constants/umlautSymbols';
import { IMAGES_CLASS, TYPOGRAPHY_CLASS, CHANGE_BUTTON_TYPE } from './constants';
import ContentfulBackgroundImageContainer from '../../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';

const DEFAULT_MAIN_LINK_TYPE: InspireButtonType = 'large';

const MainTextSize = (mainTextSize): { textClass: string; tag: keyof JSX.IntrinsicElements } => {
    let textClass = '';
    let tag = 'h2' as keyof JSX.IntrinsicElements;
    switch (mainTextSize) {
        case 'h1':
            tag = 'h1';
            textClass = TYPOGRAPHY_CLASS.MAIN_TEXT_H1_SIZE;
            break;
        case 'h2':
            textClass = TYPOGRAPHY_CLASS.MAIN_TEXT_H2_SIZE;
            break;
        default:
            textClass = TYPOGRAPHY_CLASS.MAIN_TEXT;
    }
    return { textClass, tag };
};

const MainTextSection = ({
    mainTextLink,
    mainText,
    gtmId,
    mainTextSize,
    centerContent,
}: {
    mainText: string;
    mainTextLink?: IMainBannerFields['mainTextLink'];
    gtmId: string;
    mainTextSize: string;
    centerContent?: boolean;
}): JSX.Element => {
    const hasUmlautSymbols = UMLAUT_SYMBOLS_REGEX.test(mainText);
    const MainText = () => {
        const textSize = MainTextSize(mainTextSize);
        return (
            mainText?.trim() && (
                <div>
                    <TextWithTrademark
                        tag={textSize.tag}
                        text={mainText}
                        data-gtm-id={gtmId}
                        className={classNames(styles.mainText, textSize.textClass, {
                            [styles.centerContent]: centerContent,
                            [styles.mainTextUmlaut]: hasUmlautSymbols,
                        })}
                    />
                </div>
            )
        );
    };

    return mainTextLink ? (
        <InspireLink link={mainTextLink}>
            <MainText />
        </InspireLink>
    ) : (
        <MainText />
    );
};

export const ImageSection: FC<{
    image: Asset;
    imageLink?: IMainBannerFields['leftImageLink'];
    gtmId: string;
    className?: string;
}> = ({ image, imageLink, gtmId, className }) => {
    const imageElement = (
        <ContentfulImage
            gtmId={gtmId}
            asset={image}
            maxWidth={IMAGES_CLASS.maxWidth}
            inlineStyles={{ objectFit: IMAGES_CLASS.objectFit }}
        />
    );

    return (
        <div data-gtm-id={gtmId} className={classNames(styles.imageSection, className)}>
            {imageLink ? (
                <InspireLink link={imageLink} className={styles.topLink}>
                    {imageElement}
                </InspireLink>
            ) : (
                imageElement
            )}
        </div>
    );
};

interface IMainBannerProps extends ISectionComponentProps {
    entry: IMainBanner;
}

export default function MainBanner(props: IMainBannerProps): JSX.Element {
    const fields = props.entry.fields;
    const isSmallVariant = useMediaQuery('(max-width: 1024px)');
    const classes = props.classes?.mainBanner;

    if (!fields) return null;

    const {
        backgroundColor,
        backgroundImage,
        bottomText,
        mainLinkHref,
        mainLinkText,
        mainText,
        mainTextLink,
        rightImage,
        rightImageLink,
        leftImage,
        leftImageLink,
        secondaryLink,
        secondaryLinkHref,
        topIcon,
        topText,
        mainTextSize,
        verticallyCentered,
        mainLinkType = DEFAULT_MAIN_LINK_TYPE,
        backgroundVideo,
        backgroundForMobile,
        centeredContent,
        overlayForBackgroundImage,
        legalMessage,
        viewDetails,
        mainTextColor,
        topTextColor,
        bottomTextColor,
        topTextSize,
    } = fields;

    const bgImageUrl = backgroundForMobile && isSmallVariant ? backgroundForMobile : backgroundImage;
    const hasBgVideo = !!backgroundVideo;

    const gtmId = getGtmIdByName('mainBanner', mainText);

    const topTextHexColor = topTextColor?.fields?.hexColor;
    const mainTextHexColor = mainTextColor?.fields?.hexColor;
    const bottomTextHexColor = bottomTextColor?.fields?.hexColor;

    return (
        <div className="mainBannerContainer">
            <ContentfulBackgroundImageContainer
                asset={bgImageUrl}
                assetLoadingType={'eager'}
                objectFitClassName={styles.backgroundImageObjectFit}
            >
                <div
                    data-gtm-id={gtmId}
                    className={classNames(styles.mainBanner, {
                        mainBannerConf: !hasBgVideo,
                        [styles.mainBannerLeftImage]: !!leftImage,
                        [styles.mainBannerNoRightImage]: !rightImage,
                        [styles.mainBannerVerticallyCentered]: verticallyCentered,
                        [styles.mainBannerWithBgVideo]: hasBgVideo,
                        [props.className]: props.className,
                        [classes?.mainBannerWrapper]: !!classes?.mainBannerWrapper,
                    })}
                >
                    {hasBgVideo && (
                        <VideoBlock entry={backgroundVideo} className={styles.bgVideo} width="0" height="0" />
                    )}
                    {!centeredContent && leftImage && (
                        <ImageSection image={leftImage} imageLink={leftImageLink} gtmId={gtmId} />
                    )}
                    <div
                        data-gtm-id={gtmId}
                        className={classNames(styles.actionsSection, {
                            [styles.centeredContentWrapper]: centeredContent,
                        })}
                    >
                        <div data-gtm-id={gtmId} className={styles.topIconBox}>
                            {topIcon && (
                                <div data-gtm-id={gtmId} className={styles.topIconSection}>
                                    <ContentfulImage asset={topIcon} className={styles.topIcon} />
                                </div>
                            )}
                            {topText && (
                                <div
                                    className={classNames({
                                        [styles.wrapperTopText]: !!topTextHexColor,
                                        ['topTextColor']: !!topTextHexColor,
                                    })}
                                >
                                    <TextWithTrademark
                                        tag="span"
                                        text={topText}
                                        data-gtm-id={gtmId}
                                        className={classNames('t-stylized', styles.topIconText, {
                                            [styles.centerContent]: centeredContent,
                                            [TYPOGRAPHY_CLASS.TOP_TEXT_H2]: topTextSize === 'h2',
                                            [TYPOGRAPHY_CLASS.TOP_TEXT]: topTextSize !== 'h2',
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className={classNames({
                                [styles.wrapperMainText]: !!mainTextColor,
                                ['mainTextColor']: !!mainTextColor,
                            })}
                        >
                            <MainTextSection
                                mainText={mainText}
                                gtmId={gtmId}
                                mainTextLink={mainTextLink}
                                mainTextSize={mainTextSize}
                                centerContent={centeredContent}
                            />
                        </div>
                        <div
                            className={classNames(styles.bottomText, {
                                [styles.wrapperBottomText]: !!bottomTextColor,
                                ['bottomTextColor']: !!bottomTextColor,
                            })}
                        >
                            {bottomText && (
                                <RichText
                                    className={classNames('t-paragraph', {
                                        [styles.centerContent]: centeredContent,
                                    })}
                                    text={bottomText}
                                    data-gtm-id={gtmId}
                                />
                            )}
                            {legalMessage && (
                                <LegalMessage className={styles.legalMessage} legalMessage={legalMessage} />
                            )}
                            {viewDetails && <ViewDetails viewDetails={viewDetails} />}
                        </div>
                        <div
                            className={classNames(styles.buttonsBlock, {
                                [styles.isDualButton]: secondaryLink && secondaryLinkHref,
                            })}
                        >
                            {mainLinkText && mainLinkHref && (
                                <InspireButton
                                    link={mainLinkHref}
                                    text={mainLinkText}
                                    type={isSmallVariant && CHANGE_BUTTON_TYPE ? 'primary' : mainLinkType}
                                    gtmId={getGtmIdByName('mainBanner', mainLinkText)}
                                    className={classNames(styles.mainLink, 'truncate')}
                                    linkClassName="truncate"
                                />
                            )}
                            {secondaryLink && secondaryLinkHref && (
                                <InspireButton
                                    link={secondaryLinkHref}
                                    text={secondaryLink}
                                    type={isSmallVariant && CHANGE_BUTTON_TYPE ? 'primary' : mainLinkType}
                                    gtmId={getGtmIdByName('mainBanner', secondaryLink)}
                                    className={classNames(styles.secondaryLink, 'truncate')}
                                    linkClassName="truncate"
                                />
                            )}
                        </div>
                    </div>
                    {!centeredContent && rightImage && (
                        <ImageSection
                            className={styles.rightImageSection}
                            image={rightImage}
                            imageLink={rightImageLink}
                            gtmId={gtmId}
                        />
                    )}
                </div>
            </ContentfulBackgroundImageContainer>
            <style jsx>{`
                .mainBannerConf {
                    box-shadow: ${!overlayForBackgroundImage ? `inset 0 0 0 100vh #${backgroundColor}` : ''};
                }
                .mainBannerContainer {
                    background-color: ${backgroundColor ? `#${backgroundColor}` : 'transparent'};
                }
                .topTextColor {
                    color: ${topTextHexColor ? `#${topTextHexColor}` : ''};
                }
                .mainTextColor {
                    color: ${mainTextHexColor ? `#${mainTextHexColor}` : ''};
                }
                .bottomTextColor {
                    color: ${bottomTextHexColor ? `#${bottomTextHexColor}` : ''};
                }
            `}</style>
        </div>
    );
}
