import React from 'react';
import Icon from '../../atoms/BrandIcon';

export const BEER_TYPE_INFO = {
    CRAFT: {
        title: 'CRAFT',
        icon: <Icon icon="order-craft-beer" />,
    },
    DOMESTIC: {
        title: 'DOMESTIC',
        icon: <Icon icon="order-domestic-beer" />,
    },
    IMPORTED: {
        title: 'IMPORTED',
    },
};

export const BEER_TYPE_ORDER = Object.keys(BEER_TYPE_INFO);
