import classnames from 'classnames';
import React from 'react';
import { IEventCardFields } from '../../../@generated/@types/contentful';
import ContentfulImage from '../ContentfulImage';
import LegalMessage from '../legalMessage';
import { InspireLink } from '../link';

import styles from './index.module.css';

const EventCard = (props: IEventCardFields): JSX.Element => {
    const {
        eventIcon,
        eventName,
        eventDescription,
        eventLinkHref,
        eventLinkText,
        backgroundColor,
        legalMessage,
    } = props;
    const bgColor = backgroundColor?.fields?.hexColor;

    const isLink = eventLinkHref && eventLinkText;
    if (!(bgColor || eventDescription || eventIcon || isLink || eventName)) return null;

    return (
        <>
            <div className={classnames(styles.eventCard, 'backgroundCol')}>
                {(eventIcon || eventName) && (
                    <div className={styles.eventHeader}>
                        {eventIcon && <ContentfulImage className={styles.eventIcon} asset={eventIcon} maxWidth={24} />}
                        {eventName && <p className={classnames('t-subheader-small', 'truncate')}>{eventName}</p>}
                    </div>
                )}

                {(eventDescription || legalMessage) && (
                    <p className={classnames('t-paragraph-small', 'truncate-at-2', styles.eventDescription)}>
                        {eventDescription}
                        {legalMessage && (
                            <LegalMessage className={classnames(styles.legalMessage)} legalMessage={legalMessage} />
                        )}
                    </p>
                )}

                {eventLinkHref && eventLinkText && (
                    <>
                        <InspireLink className={classnames('truncate', styles.eventLink)} link={eventLinkHref}>
                            {eventLinkText}
                        </InspireLink>
                    </>
                )}
            </div>
            <style jsx>{`
                .backgroundCol {
                    background-color: ${bgColor ? `#${bgColor}` : 'var(--col--light)'};
                }
            `}</style>
        </>
    );
};

export default EventCard;
