import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const arbysAutoCorrectedDatePipe = (props: Date) => {
    const correctedDatePipe = createAutoCorrectedDatePipe('mm/dd')(props);

    if (correctedDatePipe.value === '02/3_') {
        return { ...correctedDatePipe, value: '02/03' };
    }

    return correctedDatePipe;
};

const bwwAutoCorrectedDatePipe = (props: Date) => {
    const correctedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy')(props);

    if (correctedDatePipe.value === '02/3_/____') {
        return { ...correctedDatePipe, value: '02/03/____' };
    }

    return correctedDatePipe;
};

const autoCorrectedDatePipeMap = {
    arbys: arbysAutoCorrectedDatePipe,
    bww: bwwAutoCorrectedDatePipe,
    sdi: bwwAutoCorrectedDatePipe,
};

export const autoCorrectedDatePipe = autoCorrectedDatePipeMap[process.env.NEXT_PUBLIC_BRAND];
