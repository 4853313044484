import React, { FC } from 'react';
import { ISectionComponentProps } from '..';
import { INumberedListSection } from '../../../@generated/@types/contentful';
import NumberedList from './numberedList';
import sectionIndentsStyles from '../sectionIndents.module.css';

interface INumberedListSectionProps extends ISectionComponentProps {
    entry: INumberedListSection;
}

export interface INumberedListData {
    listTitle?: string;
    listDescription?: string;
    listItems: { itemTitle: string; itemDescription?: string }[];
}

const transformNumberedListEntryToData = (entry: INumberedListSection): INumberedListData => {
    const { fields } = entry;
    return {
        listTitle: fields.title,
        listDescription: fields.description,
        listItems: [
            ...fields.items.map(({ fields }) => {
                return {
                    itemTitle: fields.title,
                    itemDescription: fields.description,
                };
            }),
        ],
    };
};

const NumberedListSection: FC<INumberedListSectionProps> = ({ entry }) => {
    const numberedListData = transformNumberedListEntryToData(entry);

    return (
        <div className={sectionIndentsStyles.wrapper}>
            <NumberedList data={numberedListData} />
        </div>
    );
};

export default NumberedListSection;
