import { useState } from 'react';
import { getCardBalance } from '../services/cardBalance';

interface ICardBalanceHook {
    balance: number | null;
    loading: boolean;
    error: string | null;
    code: TCardBalanceCode | null;
    getBalance: (
        cardNumber: string,
        cardPin: string
    ) => Promise<{
        balance?: number;
        message?: string;
        code?: string;
    }>;
}

export enum TCardBalanceCode {
    SUCCESS = 'SUCCESS',
    INVALID = 'INVALID',
    DECLINED = 'DECLINED',
    OTHER = 'OTHER',
    GIFT_CARD_WITH_ZERO_BALANCE = 'GIFT_CARD_ZERO_BALANCE',
    GIFT_CARD_IS_IN_USE = 'GIFT_CARD_IS_IN_USE',
    GIFT_CARD_INVALID_CARD_OR_PIN = 'GIFT_CARD_INVALID_CARD_OR_PIN',
}

export default function useCardBalance(): ICardBalanceHook {
    const [balance, setBalance] = useState<number | null>(null);
    const [code, setCode] = useState<TCardBalanceCode | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getBalance = async (cardNumber: string, cardPin: string) => {
        setLoading(true);
        setBalance(null);
        setError(null);
        setCode(null);

        try {
            const res = await getCardBalance(cardNumber, cardPin);

            if (res.balance) {
                setBalance(res.balance);
                setCode(TCardBalanceCode.SUCCESS);
            } else {
                setError(res.message);
                setCode(res.code as TCardBalanceCode);
            }

            setLoading(false);

            return res;
        } catch (e) {
            setLoading(false);
            setCode(TCardBalanceCode.OTHER);
            setError('Card Information Not Available');

            return {
                code: TCardBalanceCode.OTHER,
                message: 'Card Information Not Available',
            };
        }
    };

    return { balance, loading, error, code, getBalance };
}
