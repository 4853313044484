import React, { FC } from 'react';
import { ILocationByStateOrProvinceDetailsModel } from '../../../@generated/webExpApi';
import styles from './nearbyLocationsSection.module.css';
import LocationsListItem from '../../organisms/locationsPageContent/pickupSearchResults/locationsList/locationsListItem';
import { INearbyLocationsSection } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';
import SectionHeader from '../../atoms/sectionHeader';
import { useNearbyLocationsSection } from './useNearbyLocationsSection';
import classNames from 'classnames';

export interface INearbyLocationsSectionProps extends ISectionComponentProps {
    data?: {
        locationDetails?: ILocationByStateOrProvinceDetailsModel;
    };
    entry: INearbyLocationsSection;
    classes?: { [key: string]: { [key: string]: string } };
}

const NearbyLocationsSection: FC<INearbyLocationsSectionProps> = (props) => {
    const locationDetails = props.data?.locationDetails;
    const { header, locationsLimit } = props.entry.fields;
    const { classes } = props;

    const { nearbyLocations, isOAEnabled, onOrderClick } = useNearbyLocationsSection({
        locationDetails,
        locationsLimit,
    });

    return nearbyLocations && nearbyLocations.length > 0 ? (
        <div className={classNames(styles.wrapper, classes.sectionBanner.nearByLocationWrapper)}>
            <SectionHeader
                showBullet={true}
                text={header}
                tag={'h2'}
                className={styles.header}
                textClassName={'t-header-h2'}
            />
            <div className={classNames(styles.locationTilesWrapper, classes.sectionBanner.innerWrapper)}>
                {nearbyLocations.map((location) => {
                    return (
                        <div key={`Nearby-location_${location.id}`} className={styles.tileWrapper}>
                            <LocationsListItem
                                key={location.id}
                                location={location}
                                onLocationSet={() => onOrderClick(location.id)}
                                onLocationSelect={() => onOrderClick(location.id)}
                                selected={false}
                                isCurrentLocation={false}
                                listNumber={undefined}
                                hideStoreFeatures={true}
                                hideSelectStoreLink={true}
                                isOAEnabled={isOAEnabled}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    ) : null;
};

export default NearbyLocationsSection;
