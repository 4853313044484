import React from 'react';
import { IBenefit } from '../../../@generated/@types/contentful';
import ContentfulImage from '../../atoms/ContentfulImage';
import styles from './benefit.module.css';
import classnames from 'classnames';

interface IBenefitItem {
    item: IBenefit;
}

const BenefitItem = (props: IBenefitItem): JSX.Element => {
    const { item } = props;
    const { title, description, icon } = item.fields;
    return (
        <div className={styles.benefitContainer}>
            <div className={styles.imageContainer}>
                <ContentfulImage asset={icon} />
            </div>
            <div className={styles.textContainer}>
                <span className={classnames('t-subheader-smaller', styles.benefitTitle)}>{title}</span>
                <span className={classnames('t-paragraph-hint', styles.benefitDescription)}>{description}</span>
            </div>
        </div>
    );
};

export default BenefitItem;
