/**
 * Method to filter page sections by flag and auth status
 * @method filterSections
 * @param {boolean} isAuthenticated
 * @param {boolean} showOnlyForAuthenticatedUsers flag from contentful
 * @author Yauheni Rohau <yrohau@inspirebrands.com>
 * @added 2023-03-27
 * @version 1.0
 * @memberOf filterSections
 * @returns {boolean}
 * @example
 * pageSections.filter((pageSection: IPageSection) => {
 *   return filterSections(isAuthenticated, pageSection?.fields?.showOnlyForAuthenticatedUsers);
 * })
 */

export const filterSections = (isAuthenticated: boolean, showOnlyForAuthenticatedUsers?: boolean) => {
    if (showOnlyForAuthenticatedUsers === undefined) {
        return true;
    }

    if (showOnlyForAuthenticatedUsers) {
        return isAuthenticated;
    }

    if (!showOnlyForAuthenticatedUsers) {
        return !isAuthenticated;
    }
};
