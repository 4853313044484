import React, { FC } from 'react';
import classNames from 'classnames';

import { ISectionComponentProps } from '..';
import { ITextBlockSection } from '../../../@generated/@types/contentful';
import SectionHeader from '../../atoms/sectionHeader';
import RichText from '../../atoms/richText';
import sectionIndentsStyles from '../sectionIndents.module.css';
import styles from './textBlock.module.css';
import AccordionItem from './accordionItem';
import { SHOW_BULLET } from './constants';
interface ITextBlocksProps extends ISectionComponentProps {
    entry: ITextBlockSection;
}

const TextBlocks: FC<ITextBlocksProps> = ({
    entry: {
        fields: { title, subtitle, text, accordionItems },
    },
}) => (
    <div className={sectionIndentsStyles.wrapper}>
        {title && <SectionHeader text={title} className={styles.title} tag="h2" showBullet={SHOW_BULLET} />}
        <div className={styles.container}>
            {subtitle && <h3 className={classNames('t-header-h3', styles.subtitle)}>{subtitle}</h3>}
            <RichText text={text} className={styles.text} />
            {accordionItems && (
                <div className={styles.accordionItems}>
                    {accordionItems.map((item) => (
                        <AccordionItem entry={item} contentClassName={styles.text} key={item.sys.id} />
                    ))}
                </div>
            )}
        </div>
    </div>
);

export default TextBlocks;
