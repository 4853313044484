import { useDomainProduct } from '../../redux/hooks/domainMenu';

export interface IUseProductIsSaleable {
    isSaleable: boolean;
}

export const useProductIsSaleable = (productId: string): IUseProductIsSaleable => {
    const isSaleable = useDomainProduct(productId)?.isSaleable;

    return {
        isSaleable,
    };
};
