import React from 'react';
import { IErrorBanner } from '../../../@generated/@types/contentful';
import { ISectionComponentProps } from '..';

import ErrorBannerContent from '../../clientOnly/errorBannerContent';
import { SHOW_GO_BACK_BUTTON } from './constants';

export interface IErrorBannerProps extends ISectionComponentProps {
    entry: IErrorBanner;
}

export default function ErrorBanner(props: IErrorBannerProps): JSX.Element {
    const fields = props.entry.fields;

    if (!fields) return null;

    return <ErrorBannerContent fields={props.entry.fields} enableGoBack={SHOW_GO_BACK_BUTTON}></ErrorBannerContent>;
}
