import React from 'react';
import classnames from 'classnames';
import { Asset } from 'contentful';

import { InspireButton } from '../../atoms/button';

import formStyles from './index.module.css';
import styles from './message.module.css';
import ContentfulImage from '../../atoms/ContentfulImage';

interface IMessageProps {
    icon?: Asset;
    headerText: string;
    messageText: string;
}

const Message = (props: IMessageProps): JSX.Element => {
    const { icon, headerText, messageText } = props;
    return (
        <div className={classnames(formStyles.formContainer, styles.container)}>
            <ContentfulImage maxWidth={60} asset={icon} />
            <h4 className={classnames('t-header-h3', styles.header)}>{headerText}</h4>
            <p className={classnames('t-paragraph', styles.text)}>{messageText}</p>
            <div className={formStyles.buttonWrapper}>
                <InspireButton link={'/menu'} text="Continue" />
            </div>
        </div>
    );
};

export default Message;
