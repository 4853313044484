import React, { FC } from 'react';
import classNames from 'classnames';

import { ISectionComponentProps } from '../..';
import { ITemplateTextBlockSection } from '../../../../@generated/@types/contentful';
import SectionHeader from '../../../atoms/sectionHeader';
import sectionIndentsStyles from '../../sectionIndents.module.css';
import styles from '../textBlock.module.css';
import { SHOW_BULLET } from '../constants';
import useRichTextOptions from '../../../../common/hooks/useRichTextOptions';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useContentfulTemplatedText } from '../../../../common/hooks/useContentfulTemplatedText';
import AccordionItem from '../accordionItem';

interface ITemplateTextBlockProps extends ISectionComponentProps {
    entry: ITemplateTextBlockSection;
}

const TemplateTextBlock: FC<ITemplateTextBlockProps> = ({
    entry: {
        fields: { title, subtitle, text, accordionItems },
    },
}) => {
    const richTextOptions = useRichTextOptions();
    const getContentfulTemplatedText = useContentfulTemplatedText();

    return (
        <div className={sectionIndentsStyles.wrapper}>
            {title && (
                <SectionHeader
                    text={getContentfulTemplatedText(title)}
                    className={styles.title}
                    tag="h2"
                    showBullet={SHOW_BULLET}
                />
            )}
            <div className={styles.container}>
                {subtitle && (
                    <h3 className={classNames('t-header-h3', styles.subtitle)}>
                        {getContentfulTemplatedText(subtitle)}
                    </h3>
                )}
                <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                        __html: getContentfulTemplatedText(documentToHtmlString(text, richTextOptions)),
                    }}
                />
                {accordionItems && (
                    <div className={styles.accordionItems}>
                        {accordionItems.map((item) => (
                            <AccordionItem entry={item} contentClassName={styles.text} hasTemplate key={item.sys.id} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TemplateTextBlock;
