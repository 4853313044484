import React from 'react';
import classNames from 'classnames';
import Icon from '../../atoms/BrandIcon';
import isMobileScreen from '../../../lib/isMobileScreen';
import isSmallScreen from '../../../lib/isSmallScreen';

interface IAccordionProps {
    renderHeader: (isExpanded: boolean, renderIcon: () => void, onClickHandler: () => void) => void;
    renderContent: (isExpanded: boolean) => void;
    title: string;
    containerClassName?: string;
    expandedClassName?: string;
    isExpandedByDefault?: boolean;
    iconClassName?: string;
}

export default function Accordion(props: IAccordionProps): JSX.Element {
    const {
        renderHeader,
        renderContent,
        title,
        containerClassName,
        expandedClassName,
        iconClassName,
        isExpandedByDefault = false,
    } = props;
    const [isExpanded, setAccordionExpanded] = React.useState(isExpandedByDefault);

    const renderIcon = () => {
        const smallScreen = isSmallScreen();
        const mobileScreen = isMobileScreen();
        return (
            <Icon
                ariaLabel={isExpanded ? `Collapse ${title}` : `Expand ${title}`}
                className={iconClassName}
                icon={isExpanded ? 'direction-up' : 'direction-down'}
                size={smallScreen || mobileScreen ? 's' : 'm'}
                role="img"
            />
        );
    };
    const handleAccordionClick = () => {
        setAccordionExpanded(!isExpanded);
    };

    return (
        <div className={classNames(containerClassName, { [expandedClassName]: isExpanded })}>
            {renderHeader(isExpanded, renderIcon, handleAccordionClick)}
            {renderContent(isExpanded)}
        </div>
    );
}
