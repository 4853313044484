import dynamic from 'next/dynamic';
import { locationSpecificMenuCategoriesEnabled } from '../../../lib/getFeatureFlags';
import styles from './menuCategory.module.css';
import withSectionLayout from '../withSectionLayout';
import { SHOW_HEADER_BULLET, SECTION_HEADER_WITH_H1 } from './constants';

const isSSR = !locationSpecificMenuCategoriesEnabled();

const MenuCategoryUI = dynamic(import('./menuCategory'), {
    ssr: isSSR,
    // eslint-disable-next-line react/display-name
    loading: () => null,
});

const MenuCategorySection = (props) => {
    const isSonicHeader = props.data?.isMenuPage && SECTION_HEADER_WITH_H1;

    return withSectionLayout(MenuCategoryUI, {
        showBullet: SHOW_HEADER_BULLET,
        headerTag: isSonicHeader ? 'h1' : 'h2',
        headerClassName: isSonicHeader ? `${styles.menuCategorySectionTitle} t-header-h1` : undefined,
    })(props);
};

export default MenuCategorySection;
