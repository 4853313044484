import React, { FC, useEffect, useMemo, useRef } from 'react';

import styles from './openTable.module.css';
import { injectScript } from '../../../lib/injectScriptOnce';
import { isOpenTableEnabled } from '../../../lib/getFeatureFlags';
import { ISectionComponentProps } from '..';
import { ILocationByStateOrProvinceDetailsModel } from '../../../@generated/webExpApi';
import { IOpenTable } from '../../../@generated/@types/contentful';
import OpenTableWidgetStyle from './openTableStyle';
import withSectionLayout from '../withSectionLayout';

export interface IOpenTableWidgetProps extends ISectionComponentProps {
    data?: {
        locationDetails?: ILocationByStateOrProvinceDetailsModel;
    };
    entry: IOpenTable;
}

const OpenTableWidget: FC<IOpenTableWidgetProps> = (props) => {
    const locationDetails = props.data?.locationDetails;
    const { locationsMapping } = props.entry.fields;
    const isOpenTableWidgetEnabled = isOpenTableEnabled();
    const ref = useRef<HTMLDivElement>(null);

    const openTableStoreId = useMemo(() => {
        const locationId = locationDetails?.id;
        if (!locationId) return null;

        return locationsMapping.find((x) => x.fields.idpLocationId === locationId)?.fields.externalLocationId;
    }, [locationDetails, locationsMapping]);

    useEffect(() => {
        isOpenTableWidgetEnabled &&
            !!openTableStoreId &&
            injectScript(
                `//www.opentable.com/widget/reservation/loader?rid=${openTableStoreId}&domain=com&type=standard&theme=wide&lang=en-us&overlay=false&iframe=false`,
                ref
            );
    }, [isOpenTableWidgetEnabled, openTableStoreId]);

    if (!isOpenTableWidgetEnabled || !openTableStoreId) return null;

    return (
        <div className={styles.openTable}>
            <div ref={ref}></div>
            <OpenTableWidgetStyle />
        </div>
    );
};

export default withSectionLayout(OpenTableWidget, {
    headerClassName: styles.header,
});
