import React, { FC, useEffect, useMemo, useRef } from 'react';

import styles from './dineTime.module.css';
import { injectScript } from '../../../lib/injectScriptOnce';
import { isDineTimeEnabled } from '../../../lib/getFeatureFlags';
import { ISectionComponentProps } from '..';
import { ILocationByStateOrProvinceDetailsModel } from '../../../@generated/webExpApi';
import { IDineTime } from '../../../@generated/@types/contentful';
import isMobileScreen from '../../../lib/isMobileScreen';

export interface IDineTimeWidgetProps extends ISectionComponentProps {
    data?: {
        locationDetails?: ILocationByStateOrProvinceDetailsModel;
    };
    entry: IDineTime;
}

const DineTimeWidget: FC<IDineTimeWidgetProps> = (props) => {
    const locationDetails = props.data?.locationDetails;
    const { locationsMapping } = props.entry.fields;
    const isDineTimeWidgetEnabled = isDineTimeEnabled();
    const isMobile = isMobileScreen();
    const widgetClassName = 'F05A9BA6-2297-4142-BE3B-437BE3C0AB77';
    const widgetHostName = '//widget.qsr.cloud';
    const ref = useRef<HTMLDivElement>(null);

    const dineTimeStoreId = useMemo(() => {
        const locationId = locationDetails?.id;
        if (!locationId) return null;

        return locationsMapping.find((x) => x.fields.idpLocationId === locationId)?.fields.externalLocationId;
    }, [locationDetails, locationsMapping]);

    useEffect(() => {
        isDineTimeWidgetEnabled &&
            !!dineTimeStoreId &&
            injectScript('https://widget.qsr.cloud/dist/embed/embed.js', ref);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isDineTimeWidgetEnabled || !dineTimeStoreId) return null;

    return (
        <div className={styles.dineTime}>
            <div
                className={widgetClassName}
                data-hostname={widgetHostName}
                data-widgettype={isMobile ? 'standard' : 'compact'}
                data-siteuid={dineTimeStoreId}
                data-widgetmode="reservation"
            />
            {!isMobile && (
                <div
                    className={widgetClassName}
                    data-hostname={widgetHostName}
                    data-widgettype="compact"
                    data-siteuid={dineTimeStoreId}
                    data-widgetmode="waitlist"
                />
            )}
            <div ref={ref}></div>
        </div>
    );
};

export default DineTimeWidget;
