import React, { FC } from 'react';
import classnames from 'classnames';
import styles from '../viewDetails.module.css';
import { Asset } from 'contentful';
import ContentfulImage from '../../../atoms/ContentfulImage';

export type IViewDetailsContentProps = {
    productText?: string;
    productSubtext?: string;
    expiresText?: string;
    statementText?: string;
    productImage?: Asset;
};

export const ViewDetailsContent: FC<IViewDetailsContentProps> = ({
    productText,
    productSubtext,
    expiresText,
    productImage,
    statementText,
}) => {
    return (
        <div className={styles.content}>
            <div className={styles.mainContent}>
                <div className={styles.textBlock}>
                    <p className={classnames('t-header-h1', styles.productText)}>{productText}</p>
                    <p className={classnames('t-paragraph-small-strong', styles.productSubtext)}>{productSubtext}</p>
                    <p className={'t-paragraph-hint'}>{expiresText}</p>
                </div>
                <div className={styles.imageWrapper}>
                    <ContentfulImage asset={productImage} maxWidth={400} />
                </div>
                <p className={classnames('t-paragraph-hint', styles.statementText)}>{statementText}</p>
            </div>
        </div>
    );
};
