import { CustomerSignUpRequest, WebExperienceApi } from '../../../@generated/webExpApi';
import addJsonError from '../addJsonError';

export interface ISignUpPayload {
    firstName: string;
    lastName: string;
    email: string;
    postalCode?: string;
    storeId?: string;
    storeName?: string;
    birthDate?: string;
}

const signUp = async (payload: ISignUpPayload): Promise<void> => {
    const expApi = new WebExperienceApi();

    const body: CustomerSignUpRequest = {
        iSignUpModelModel: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            birthDate: payload.birthDate || undefined,
            postalCode: payload.postalCode,
            locationName: payload.storeName,
            locationId: payload.storeId,
        },
    };

    await addJsonError('signup', expApi.customerSignUp(body));
};

export default signUp;
