import React, { FC } from 'react';
import PageSections from '..';
import { IAction } from '../../../@generated/@types/contentful';
import { usePersonalization } from '../../../redux/hooks';
import BrandLoader from '../../atoms/BrandLoader';
import styles from './action.module.css';

interface ActionProps {
    entry: IAction;
}

export const Action: FC<ActionProps> = ({
    entry: {
        fields: { defaultSection, tableReference },
    },
    ...props
}) => {
    const {
        loading,
        actions: { getPersonalizedSection },
    } = usePersonalization();

    const render = () => {
        if (loading) {
            return (
                <div className={styles.loader}>
                    <BrandLoader />
                </div>
            );
        }

        const personalizedSection = getPersonalizedSection(tableReference);

        const section = personalizedSection?.fields.section || defaultSection;

        return <>{section ? <PageSections pageSections={[section]} {...props} /> : <></>}</>;
    };

    return render();
};
