import React, { FC } from 'react';
import classnames from 'classnames';

import HScroller from '../../atoms/HScroller';
import { getGtmIdByName } from '../../../lib/gtm';
import ContentfulImage from '../../atoms/ContentfulImage';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR } from './constants';
import ContentfulBackgroundImageContainer from '../../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';
import styles from './topPicks.module.css';
import { ITopPicksProps } from './types';

const TopPicks: FC<ITopPicksProps> = ({ entry, renderProductCard }: ITopPicksProps): JSX.Element => {
    const fields = entry.fields;

    const {
        leftSideTopText: subtitle,
        leftSideMainText: title,
        leftSideImage,
        category,
        backgroundColor,
        textColor,
        backgroundImage,
        isSideScroll,
    } = fields;
    const {
        products,
        link: {
            fields: { nameInUrl },
        },
    } = category.fields;

    const gtmId = 'topPicks';

    return (
        <div className={styles.container}>
            <ContentfulBackgroundImageContainer asset={backgroundImage} assetLoadingType={'eager'}>
                {!backgroundImage ? <div className={classnames('bgBlock', styles.bgBlock)}></div> : undefined}
                <div
                    data-gtm-id={gtmId}
                    className={classnames(styles.topPicks, {
                        [styles.withSubtitle]: !!subtitle,
                    })}
                >
                    <div data-gtm-id={gtmId} className={classnames('content', styles.content)}>
                        <div data-gtm-id={gtmId} className={styles.wrapperHeadings}>
                            {subtitle?.trim() && (
                                <h2 data-gtm-id={gtmId} className={styles.subtitle}>
                                    {subtitle}
                                </h2>
                            )}
                            <h2 data-gtm-id={gtmId} className={classnames('t-header-h1', styles.title)}>
                                {title}
                            </h2>
                            {leftSideImage && (
                                <ContentfulImage
                                    asset={leftSideImage}
                                    maxWidth={257}
                                    className={styles.leftSideImage}
                                    imageAlt={''}
                                    loading={'eager'}
                                />
                            )}
                        </div>
                    </div>
                    <div data-gtm-id={gtmId} className={styles.topPicksContainer}>
                        <HScroller
                            listClassName={classnames(styles.items, {
                                [styles.itemsGrid]: !isSideScroll,
                            })}
                            className={classnames(styles.itemsWrapper, {
                                [styles.itemsWrapperGrid]: !isSideScroll,
                            })}
                            scrollStep={225}
                            prevButtonClassName={styles.prevButton}
                            nextButtonClassName={styles.nextButton}
                        >
                            {products.map((product, index) =>
                                renderProductCard({
                                    isSideScroll,
                                    categoryUrl: nameInUrl,
                                    product,
                                    className: classnames(styles.item, {
                                        [styles.itemGrid]: !isSideScroll,
                                    }),
                                    gtmId: getGtmIdByName(`topPicksItem-${index}`, product.fields?.name),
                                })
                            )}
                        </HScroller>
                    </div>
                </div>
            </ContentfulBackgroundImageContainer>
            <style jsx>{`
                .bgBlock {
                    background-color: ${backgroundColor ? `#${backgroundColor}` : DEFAULT_BACKGROUND_COLOR};
                }
                .content {
                    color: ${textColor ? `#${textColor}` : DEFAULT_TEXT_COLOR};
                }
            `}</style>
        </div>
    );
};

export default TopPicks;
