import classnames from 'classnames';
import React, { useState } from 'react';
import { IViewDetails } from '../../../@generated/@types/contentful';
import RewardDetailsModal from '../rewardDetailsModal';
import { InspireButton } from '../../atoms/button';
import { ViewDetailsContent } from './viewDetailsContent/viewDetailsContent';
import styles from './viewDetails.module.css';

const DEFAULT_VIEW_DETAILS_TITLE = 'View Details.';

interface IPropsViewDetails {
    className?: string;
    viewDetails: IViewDetails;
}

const ViewDetails: React.FC<IPropsViewDetails> = ({ className, viewDetails }) => {
    const {
        statementText,
        title,
        subtitle,
        secondaryLinkHref,
        secondaryLink,
        productText,
        productSubtext,
        productImage,
        mainLinkText,
        mainLinkHref,
        expiresText,
        viewDetailsTitle,
    } = viewDetails.fields;

    const [modalOpen, setModalOpen] = useState(false);

    const handleToggleModal = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const renderButtons = () => {
        return (
            <div className={styles.buttonsBlock}>
                {secondaryLink && secondaryLinkHref && (
                    <InspireButton
                        link={secondaryLinkHref}
                        text={secondaryLink}
                        type="secondary"
                        className={classnames(styles.secondaryLink, 'truncate', {
                            [styles.mobileSecondaryLink]: mainLinkText && mainLinkHref,
                        })}
                    />
                )}
                {mainLinkText && mainLinkHref && (
                    <InspireButton
                        link={mainLinkHref}
                        text={mainLinkText}
                        type="primary"
                        className={classnames(styles.mainLink, 'truncate', {
                            [styles.mobileMainLink]: secondaryLink && secondaryLinkHref,
                        })}
                    />
                )}
            </div>
        );
    };
    return (
        <div className={classnames('t-parapraph', className)} onClick={handleClick}>
            <span role="button" onClick={handleToggleModal} className={styles.viewDetails}>
                {viewDetailsTitle || DEFAULT_VIEW_DETAILS_TITLE}
            </span>
            <RewardDetailsModal
                subtitle={subtitle}
                modalTitle={title}
                open={modalOpen}
                onClose={handleToggleModal}
                renderButtons={renderButtons}
            >
                <ViewDetailsContent
                    productText={productText}
                    productSubtext={productSubtext}
                    expiresText={expiresText}
                    productImage={productImage}
                    statementText={statementText}
                />
            </RewardDetailsModal>
        </div>
    );
};

export default ViewDetails;
