import React, { FC, RefObject } from 'react';
import { IDeliveryPartners } from '../../../@generated/@types/contentful';
import ContentfulImage from '../../atoms/ContentfulImage';
import styles from './deliveryPartners.module.css';
import SectionHeader from '../../atoms/sectionHeader';
import classNames from 'classnames';
import { InspireButton } from '../../atoms/button';
import { DeliveryProviderNameEnumModel, IDeliveryProviderModel } from '../../../@generated/webExpApi';
import { SHOW_BULLET, UBEREATS, POSTMATES, GRUBHUB, DOORDASH } from './constants';
import getBrandInfo from '../../../lib/brandInfo';

export interface IDeliveryPartnersProps {
    entry: IDeliveryPartners;
    partners: IDeliveryProviderModel[];
    classes?: { [key: string]: { [key: string]: string } };
    ref?: RefObject<HTMLDivElement>;
}

const getImageUrl = (imageName: string): string => {
    const { brandId } = getBrandInfo();
    return `/brands/inspire/deliveryPartners/${brandId.toLowerCase()}/${imageName}`;
};

const partnerImageMap = {
    [DeliveryProviderNameEnumModel.DoorDash]: getImageUrl(DOORDASH),
    [DeliveryProviderNameEnumModel.GrubHub]: getImageUrl(GRUBHUB),
    [DeliveryProviderNameEnumModel.Postmates]: getImageUrl(POSTMATES),
    [DeliveryProviderNameEnumModel.UberEats]: getImageUrl(UBEREATS),
};

const DeliveryPartners: FC<IDeliveryPartnersProps> = (props) => {
    const {
        entry: {
            fields: { sectionTitle, image, contentSubtitle, contentTitle, description },
        },
        partners,
        classes,
        ref,
    } = props;
    return (
        <div ref={ref} className={classNames(styles.wrapper, classes.sectionBanner.wrapper)}>
            <SectionHeader className={styles.sectionTitle} text={sectionTitle} showBullet={SHOW_BULLET} tag="h2" />
            <div className={classNames(styles.innerWrapper, classes.sectionBanner.innerWrapper)}>
                <ContentfulImage asset={image} maxWidth={1000} className={styles.image} />
                <div className={styles.contentWrapper}>
                    <p className={classNames('t-subheader-small', 't-stylized', styles.contentSubtitle)}>
                        {contentSubtitle}
                    </p>
                    <h3 className={classNames('t-header-card-title', styles.contentTitle)}>{contentTitle}</h3>
                    <p className={classNames('t-paragraph', styles.description)}>{description}</p>
                    <div className={styles.partnersLinks}>
                        {partners.map((it) => (
                            <InspireButton
                                key={it.name}
                                link={it.url}
                                type="secondary"
                                className={classNames(styles.partnerLink, styles[it.name])}
                                newtab
                            >
                                <img
                                    src={partnerImageMap[it.name]}
                                    alt={it.name}
                                    className={classNames(styles.partnerImg, styles[it.name])}
                                />
                            </InspireButton>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryPartners;
