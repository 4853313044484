import React, { FC } from 'react';
import { IOrderHistory } from '../../../redux/orderHistory';
import RecentOrderItem from './recentOrderItem';

import styles from './recentOrdersCarousel.module.css';
import HScroller from '../../atoms/HScroller';

interface IRecentOrdersCarouselProps {
    orderHistory: IOrderHistory;
}

const RecentOrdersCarousel: FC<IRecentOrdersCarouselProps> = (props) => {
    return (
        <HScroller
            className={styles.container}
            scrollStep={400}
            nextButtonClassName={styles.nextButton}
            prevButtonClassName={styles.prevButton}
        >
            {props.orderHistory.map((order) => (
                <RecentOrderItem key={order.id} order={order} />
            ))}
        </HScroller>
    );
};

export default RecentOrdersCarousel;
