import React from 'react';

import styles from './index.module.css';
import sectionIndentsStyles from '../sectionIndents.module.css';
import FaqWithAccardion from './faqWithAccordion/faqWithAccordion';
import FaqWithoutAccardion from './faqWithoutAccordion/faqWithoutAccordion';
import SectionHeader from '../../atoms/sectionHeader';
import { ISectionComponentProps } from '..';
import { IFrequentlyAskedQuestions } from '../../../@generated/@types/contentful';
import classnames from 'classnames';
import { SHOW_BULLET } from './constants';

interface IFaqProps extends ISectionComponentProps {
    entry: IFrequentlyAskedQuestions;
}

export default function Faq(props: IFaqProps): JSX.Element {
    const { entry } = props;

    return (
        <div className={classnames(sectionIndentsStyles.wrapper, styles.container)}>
            {entry.fields.title && (
                <SectionHeader
                    text={entry.fields.title}
                    className={styles.title}
                    tag={entry.fields.type || 'h2'}
                    textClassName={entry.fields.type ? `t-header-${entry.fields.type}` : 't-header-h2'}
                    showBullet={SHOW_BULLET}
                />
            )}
            {entry.fields.withAccordion ?? true ? <FaqWithAccardion {...entry} /> : <FaqWithoutAccardion {...entry} />}
        </div>
    );
}
