import React, { FC } from 'react';

const OpenTableWidgetStyle: FC = () => {
    return (
        <style jsx>{`
            #ot-reservation-widget .ot-dtp-picker {
                width: calc(100% - 30px);
                background-color: var(--col--gray1);
            }
            #ot-reservation-widget .ot-title {
                font-family: var(--ff--header);
                font-size: 24px;
                text-transform: uppercase;
                color: var(--col--dark);
                letter-spacing: var(--button-letter-spacing);
                text-align: left;
            }
            #ot-reservation-widget .ot-powered-by {
                background-position: left;
            }
            #ot-reservation-widget .ot-dtp-picker-form {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 55px;
                border: 1px solid var(--col--gray4);
                border-radius: 2px;
            }
            #ot-reservation-widget .ot-button.ot-dtp-picker-button {
                background-color: var(--col--primary1);
                border-color: var(--col--primary1);
                text-transform: uppercase;
                box-shadow: none;
                height: 55px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: var(--ff--button);
                font-weight: normal;
                cursor: pointer;
                color: var(--button-color);
                width: 100%;
            }
            #ot-reservation-widget .ot-button.ot-dtp-picker-button:hover {
                background-color: var(--col--primary1variant);
                border-color: var(--col--primary1varianr);
            }
            #ot-reservation-widget .ot-dtp-picker-selector {
                width: 100%;
                height: 55px;
                border: none;
                display: flex;
                align-items: center;
                font-family: var(--ff--pharagraph);
                border-right: 1px solid var(--col--gray4);
            }
            #ot-reservation-widget .ot-dtp-picker-selector a {
                width: 100%;
            }
            #ot-reservation-widget .ot-dtp-picker-selector option {
                text-indent: 5px;
            }
            #ot-reservation-widget .picker__close {
                color: var(--col--primary1);
            }
            #ot-reservation-widget .picker__day--highlighted,
            .picker__nav--prev,
            .picker__nav--next {
                border-color: var(--col--primary1);
            }
            #ot-reservation-widget .picker__day:hover,
            .picker__nav--prev:hover,
            .picker__nav--next:hover {
                border-color: var(--col--primary1variant);
            }
            @media (max-width: 600px) {
                #ot-reservation-widget .ot-dtp-picker-form {
                    flex-flow: column;
                    height: auto;
                }

                #ot-reservation-widget .ot-dtp-picker-selector {
                    border-bottom: 1px solid var(--col--gray4);
                }

                #ot-reservation-widget .ot-title {
                    font-size: 20px;
                }
            }
        `}</style>
    );
};

export default OpenTableWidgetStyle;
