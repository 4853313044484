import React, { FC } from 'react';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';

import styles from './rewardDetailsModal.module.css';

import isSmallScreen from '../../../lib/isSmallScreen';
import isMobileScreen from '../../../lib/isMobileScreen';
import Icon from '../../atoms/BrandIcon';
import BrandLoader from '../../atoms/BrandLoader';
import { CHANGE_TYPOGRAPHY_MOBILE, CLOSE_ICON_TEXT } from './constants';

export interface IDetailsModal {
    open: boolean;
    isLoading?: boolean;
    modalTitle: string;
    subtitle?: string;
    onClose?: (e?: React.MouseEvent<HTMLElement>) => void;
    renderButtons?: () => JSX.Element;
    errorHint?: string;
}

const RewardDetailsModal: FC<IDetailsModal> = ({
    open,
    modalTitle,
    subtitle,
    isLoading = false,
    onClose,
    renderButtons = () => null,
    children,
    errorHint,
}) => {
    const smallScreen = isSmallScreen();
    const mobileScreen = isMobileScreen();

    const isMobile = smallScreen || mobileScreen;

    return (
        <Modal open={open} className={styles.modal} onClose={onClose}>
            <div className={styles.content}>
                <button aria-label="Close Icon" className={styles.closeButton} onClick={onClose}>
                    {CLOSE_ICON_TEXT && (
                        <span className={classnames('t-link', styles.iconText)}>{CLOSE_ICON_TEXT}</span>
                    )}
                    <Icon icon="action-close" size={isMobile ? 's' : 'm'} className={styles.closeIcon} />
                </button>
                <section className={styles.header}>
                    {subtitle && <p className={classnames('t-subheader t-stylized', styles.subtitle)}>{subtitle}</p>}
                    <h1
                        className={classnames(
                            isMobile && CHANGE_TYPOGRAPHY_MOBILE ? 't-subheader-hero' : 't-header-h1',
                            styles.modalTitle
                        )}
                    >
                        {modalTitle}
                    </h1>
                </section>
                {children}
                <div className={styles.buttonsBlock}>
                    <div className={styles.buttonsBlockWithHint}>
                        {renderButtons()}
                        {errorHint && (
                            <span className={classnames('t-paragraph-hint', styles.buttonsErrorHint)}>{errorHint}</span>
                        )}
                    </div>
                </div>
                {isLoading && <BrandLoader className={styles.loader} />}
            </div>
        </Modal>
    );
};

export default RewardDetailsModal;
