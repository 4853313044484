import {
    IContactUsRequestModel,
    ITransformedGetCustomerAccountResponseForClientModel,
} from '../../../@generated/webExpApi';

export const unmaskPhoneField = (value: string) => {
    return value.replace(/[(\s)-]/g, '');
};

export const getFormInitialValues = (
    account?: ITransformedGetCustomerAccountResponseForClientModel
): IContactUsRequestModel => {
    return {
        firstName: account?.firstName || '',
        lastName: account?.lastName || '',
        email: account?.email || '',
        phoneNumber: (account?.phones && account?.phones[0]?.number?.replace(/[-]/g, '')) || '',
        message: '',
        reason: '',
    };
};

export const PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
