import React, { useState } from 'react';
import { useIsomorphicLayoutEffect } from '../../../common/hooks/useIsomorphicLayoutEffect';
import classnames from 'classnames';
import { IAppLink, IExternalLink, IMiniBanners } from '../../../@generated/@types/contentful';
import SectionHeader from '../../atoms/sectionHeader';
import withSectionLayout from '../../sections/withSectionLayout';
import ContentfulImage from '../../atoms/ContentfulImage';
import { InspireButton } from '../../atoms/button';
import { InspireLink } from '../../atoms/link';
import { ISectionComponentProps } from '..';
import { mobileDeviceSpecificLinkFilter } from './mobileDeviceSpecificLinkFilter';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './miniBanners.module.css';

interface IMiniBannersProps extends ISectionComponentProps {
    entry: IMiniBanners;
}

const MiniBanners = (props: IMiniBannersProps): JSX.Element => {
    const isomorphicLayoutEffect = useIsomorphicLayoutEffect();
    const fields = props.entry.fields;
    const { isAuthenticated } = useAuth0();
    const { title, links } = fields;
    const [miniBannersItems, setMiniBannersItems] = useState(links);

    isomorphicLayoutEffect(() => {
        setMiniBannersItems((miniBannersItems) =>
            miniBannersItems.map((miniBanner) => ({
                ...miniBanner,
                ...(miniBanner.fields && {
                    fields: {
                        ...miniBanner.fields,
                        ...(miniBanner.fields.links && {
                            links: miniBanner.fields.links.filter(mobileDeviceSpecificLinkFilter),
                        }),
                    },
                }),
            }))
        );
    }, []);

    if (miniBannersItems.some((miniBannersItem) => !miniBannersItem.fields)) return null;

    const gtmId = 'miniBanners';

    const showDueToLoggedInState = (miniBanner) => {
        return (
            (isAuthenticated && miniBanner.fields.showOnlyWhenLoggedIn) ||
            (!isAuthenticated && miniBanner.fields.showOnlyWhenLoggedOut) ||
            (!miniBanner.fields.showOnlyWhenLoggedOut && !miniBanner.fields.showOnlyWhenLoggedIn)
        );
    };

    const atLeastOneShowsDueToLoggedInStatus = miniBannersItems.some((miniBanner) => {
        return showDueToLoggedInState(miniBanner);
    });

    return (
        atLeastOneShowsDueToLoggedInStatus && (
            <section className={styles.sectionFlexContainer} data-gtm-id={gtmId}>
                <SectionHeader text={title} tag="h2" textClassName={classnames('t-header-h2')} />
                <div data-gtm-id={gtmId} className={styles.miniBanners}>
                    {miniBannersItems.map(
                        (miniBanner) =>
                            showDueToLoggedInState(miniBanner) && (
                                <div key={miniBanner.sys.id} data-gtm-id={gtmId} className={styles.miniBanner}>
                                    <ContentfulImage maxWidth={397} asset={miniBanner.fields.image} />
                                    <div className={styles.miniBannerContent}>
                                        {miniBanner.fields.secondaryTitle?.trim() && (
                                            <h3
                                                className={classnames(
                                                    't-subheader',
                                                    't-stylized',
                                                    styles.secondaryTitle
                                                )}
                                            >
                                                {miniBanner.fields.secondaryTitle}
                                            </h3>
                                        )}
                                        {miniBanner.fields.title?.trim() && (
                                            <h2 className={classnames('t-header-card-title', styles.title)}>
                                                {miniBanner.fields.title}
                                            </h2>
                                        )}
                                        <p className={classnames('t-paragraph', styles.description)}>
                                            {miniBanner.fields.description}
                                        </p>
                                        <div className={styles.buttonSection}>
                                            {miniBanner.fields.links.map((link, idx) =>
                                                (link as IAppLink).fields?.image ? (
                                                    <InspireLink
                                                        link={(link as IAppLink).fields.url}
                                                        key={link.sys.id}
                                                        newtab
                                                    >
                                                        <ContentfulImage asset={(link as IAppLink).fields.image} />
                                                    </InspireLink>
                                                ) : (
                                                    <InspireButton
                                                        key={`miniBanner-button-${idx}`}
                                                        className="truncate"
                                                        link={link as IExternalLink}
                                                        text={(link as IExternalLink).fields.name}
                                                        type="primary"
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </section>
        )
    );
};

export default withSectionLayout(MiniBanners, { showBullet: true, headerTag: 'h2', className: styles.sectionLayout });
