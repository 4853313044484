import React, { FC, useState, useRef, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import FormikInput from '../formikInput';
import {
    validateAlpha,
    validateEmail,
    validatePhone,
    validateRequire,
} from '../../../common/helpers/complexValidateHelper';
import styles from './blazingRewardsForm.module.css';
import Dropdown from '../../atoms/dropdown';
import { InspireButton } from '../../atoms/button';
import Icon from '../../atoms/BrandIcon';
import classNames from 'classnames';
import { useAccount } from '../../../redux/hooks';
import { getFormInitialValues, PHONE_MASK, unmaskPhoneField } from './helpers';
import useLoyaltyService from '../../../common/hooks/useLoyaltyService';
import { useScreen } from '../../../common/hooks/useScreen';

interface IBlazingRewardsFormProps {
    isOpen: boolean;
    onClose: () => void;
}

const CONTACT_REASONS = [
    'Enrollment Help',
    'General Program Questions',
    'Profile Update Help',
    'Recent Transactions',
    'Redemption or Bonus Offers',
    'Cancel My Certificate',
];

const ERROR_BANNER_TIMEOUT = 3000;

export const BlazingRewardsForm: FC<IBlazingRewardsFormProps> = ({ isOpen, onClose }) => {
    const account = useAccount()?.account;
    const loyaltyService = useLoyaltyService();
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSuccessNotification, setIsShowSuccessNotification] = useState(false);
    const [isShowErrorBanner, setIsShowErrorBanner] = useState(false);
    const errorBannerTimeoutRef = useRef(null);
    const { isDesktop } = useScreen();

    const initialFormValues = getFormInitialValues(account);

    const showErrorBanner = () => {
        setIsShowErrorBanner(true);

        clearTimeout(errorBannerTimeoutRef.current);

        errorBannerTimeoutRef.current = setTimeout(() => {
            setIsShowErrorBanner(false);
        }, ERROR_BANNER_TIMEOUT);
    };

    const onSubmit = async (data) => {
        if (loyaltyService && !isLoading) {
            try {
                setIsLoading(true);

                await loyaltyService.contactUs(data);

                setIsShowSuccessNotification(true);
                onClose();
            } catch (e) {
                showErrorBanner();
            } finally {
                setIsLoading(false);
            }
        }
    };

    const closeSuccessNotification = () => setIsShowSuccessNotification(false);

    useEffect(() => {
        return () => {
            clearTimeout(errorBannerTimeoutRef.current);
        };
    }, [errorBannerTimeoutRef]);

    return (
        <>
            <Collapse in={isOpen} unmountOnExit>
                <div className={styles.wrapper}>
                    <h3 className="t-header-h3">blazin’ rewards®</h3>
                    <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
                        {({ setFieldValue }) => (
                            <Form className={styles.form}>
                                <FormikInput
                                    label="First Name"
                                    labelClassName={styles.label}
                                    inputClassName={styles.input}
                                    name="firstName"
                                    maxLength={100}
                                    placeholder="Enter First Name"
                                    required
                                    validate={validateAlpha('First name', 100)}
                                />
                                <FormikInput
                                    label="Last Name"
                                    labelClassName={styles.label}
                                    inputClassName={styles.input}
                                    name="lastName"
                                    maxLength={100}
                                    placeholder="Enter Last Name"
                                    required
                                    validate={validateAlpha('Last name', 100)}
                                />
                                <FormikInput
                                    label="Email"
                                    labelClassName={styles.label}
                                    inputClassName={styles.input}
                                    maxLength={60}
                                    name="email"
                                    placeholder="Enter Email"
                                    required
                                    validate={validateEmail('Email')}
                                />
                                <FormikInput
                                    label="Phone Number"
                                    labelClassName={styles.label}
                                    inputClassName={styles.input}
                                    type="tel"
                                    name="phoneNumber"
                                    placeholder="Enter Phone number"
                                    validate={validatePhone('Phone number')}
                                    onChange={(e) => {
                                        setFieldValue('phoneNumber', unmaskPhoneField(e.target.value));
                                    }}
                                    mask={PHONE_MASK}
                                    required
                                />
                                <Field name="reason" validate={validateRequire('Contact Reason')}>
                                    {({ field: { value }, meta }) => (
                                        <div className={styles.dropdown}>
                                            <span className={styles.label}>
                                                CONTACT REASON<span className={styles.labelStar}> *</span>
                                            </span>
                                            <Dropdown
                                                target={
                                                    <span className={styles.input}>{value || 'Select a reason'}</span>
                                                }
                                            >
                                                {CONTACT_REASONS.map((el, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        component="div"
                                                        className={styles.input}
                                                        onClick={() => setFieldValue('reason', el)}
                                                    >
                                                        {el}
                                                    </MenuItem>
                                                ))}
                                            </Dropdown>
                                            {meta.touched && meta.error && (
                                                <span className={styles.inputError}>{meta.error}</span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                                <FormikInput
                                    label="Message"
                                    inputClassName={styles.input}
                                    labelClassName={styles.label}
                                    name="message"
                                    multiline
                                    placeholder="Enter message"
                                    required
                                    maxLength={255}
                                    validate={validateRequire('Message')}
                                />
                                <div className={styles.submitButton}>
                                    <InspireButton submit text={'SUBMIT'} disabled={isLoading} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Collapse>
            <Modal open={isShowSuccessNotification} onClose={closeSuccessNotification}>
                <Fade in={isShowSuccessNotification}>
                    <div className={styles.submitModal}>
                        <Icon
                            icon="action-close"
                            className={styles.closeIcon}
                            onClick={closeSuccessNotification}
                            size={isDesktop ? 'm' : 's'}
                        />
                        <div className={styles.modalText}>
                            <div>
                                <Icon icon="brand-star" className={styles.starIcon} />
                            </div>
                            <h3 className="t-header-h3">thanks for submitting a note!</h3>
                            <p className="t-paragraph">
                                Your message has been submitted successfully! Our team members are taking a look and
                                you’ll have a response in your inbox within 2-3 business days.
                            </p>
                        </div>
                        <InspireButton submit text={'CLOSE'} onClick={closeSuccessNotification} />
                    </div>
                </Fade>
            </Modal>
            <Fade in={isShowErrorBanner}>
                <div className={classNames(styles.error, 't-subheader-smaller')}>
                    <Icon icon="info-error" />
                    <span>TECHNICAL ERROR:&nbsp;</span>
                    <span>Please check your internet connection and try again.</span>
                </div>
            </Fade>
        </>
    );
};
